import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface TransactionSettingsState {
  slippage: 'Auto' | number
  priorityFee: number
  updateSlippage: (slippage: 'Auto' | number) => void
  updatePriorityFee: (priorityFee: number) => void
}

export const MIN_SLIPPAGE = 0.5
export const MAX_SLIPPAGE = 100
export const DEFAULT_CUSTOM_SLIPPAGE = 0.5

export const MIN_PRIORITY_FEE = 0.00001
export const MAX_PRIORITY_FEE = 1
export const RANGE_PRIORITY_FEE = [0.01, 0.02, 0.05]

export const useTransactionSettingsStore = create(
  persist<TransactionSettingsState>(
    (set) => ({
      slippage: 'Auto',
      priorityFee: 0.01,
      updateSlippage: (slippage): void => {
        if (slippage === 'Auto') set({ slippage })
        else {
          set({
            slippage: Math.min(MAX_SLIPPAGE, Math.max(MIN_SLIPPAGE, slippage))
          })
        }
      },
      updatePriorityFee: (priorityFee): void => {
        set({
          priorityFee: Math.min(MAX_PRIORITY_FEE, Math.max(MIN_PRIORITY_FEE, priorityFee))
        })
      }
    }),
    {
      name: 'transaction-settings-storage',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
