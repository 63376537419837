import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { SolanaGlobalClient } from './client'
import type { TokenAccountType, Web3TokensMint } from '@turbx/solana'
import type { DigitalAssetWithToken, Metadata } from '@metaplex-foundation/mpl-token-metadata'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AddressesType = any

// https://github.com/lukemorales/query-key-factory

export const solanaQueries = createQueryKeyStore({
  assets: {
    fetchEarlyAccess: (owner: AddressesType | undefined) => ({
      queryKey: [{ owner }],
      queryFn: (): Promise<DigitalAssetWithToken | null> => SolanaGlobalClient.fetchEarlyAccessTokenAccount(owner!),
      enabled: !!owner
    }),
    all: (owners: AddressesType[]) => ({
      queryKey: [{ owners }],
      queryFn: (): Promise<DigitalAssetWithToken[]> => SolanaGlobalClient.fetchMultipleEarlyAccessTokenAccounts(owners)
    }),
    getBalance: (owner: AddressesType | undefined) => ({
      queryKey: [{ owner }],
      queryFn: (): Promise<number> => SolanaGlobalClient.getBalance(owner!),
      enabled: !!owner
    }),
    getMultipleBalances: (addresses: [AddressesType, ...AddressesType[]] | undefined) => ({
      queryKey: [addresses],
      queryFn: (): Promise<number[]> => SolanaGlobalClient.getMultipleBalances(addresses!),
      enabled: !!addresses
    }),
    getTokenAccounts: (owner: AddressesType | undefined) => ({
      queryKey: [{ owner }],
      queryFn: (): Promise<TokenAccountType[]> => SolanaGlobalClient.getTokenAccounts(owner!),
      enabled: !!owner
    }),
    tokenMetadatas: (mints: Web3TokensMint[] | undefined) => ({
      queryKey: [{ mints }],
      queryFn: (): Promise<Metadata[]> => SolanaGlobalClient.tokensMetadata(mints!),
      enabled: !!mints
    })
  }
})
