import { Modal, Slider, connectWalletModalId, useModal, useNotificationStore } from '@/deprecated-components'
import { trpc, usePlatformStore } from '@/stores'
import { HStack, Wrap, Divider, Grid, GridItem, Flex, Box, VStack } from '@/styled-system/jsx'
import { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTransactionSettingsStore } from './transaction-settings.store'
import { DECIMALS, createDataset } from '@/utils'
import { Spinner } from '@turbx/deprecated-design-system'
import { LAMPORTS_PER_SOL } from '@/constant'
import { captureException } from '@sentry/react'
import { toast } from 'react-toastify'
import { debounce } from '@turbx/active-support'
import fromExponential from 'from-exponential'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedToken = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SwapInputWithPair = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TokenPairType = any

const RANGE_SELL_PERCENTAGE = [0.2, 0.3, 0.5, 0.7, 1]

export interface TransactionSellProps {
  token: NormalizedToken
  pairAddress: TokenPairType | undefined
  tokenBalance: string
  isWalletConnect: boolean
}

const useDebounce = (callback: (options: { preflight: true }) => void): (() => void) => {
  const ref = useRef<(options: { preflight: true }) => void>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (): void => {
      ref.current?.({ preflight: true })
    }

    return debounce(func, 300)
  }, [])

  return debouncedCallback
}

export const TransactionSell: FC<TransactionSellProps> = ({ token, pairAddress, isWalletConnect, tokenBalance }) => {
  const { t } = useTranslation()
  const utils = trpc.useUtils()

  const alert = useNotificationStore((state) => state.alert)

  const screenWidth = usePlatformStore((state) => state.dimensions.screenWidth)

  const sellConfirmModal = useModal()
  const sellCustomModal = useModal()
  const connectWalletModal = useModal(connectWalletModalId)

  const slippage = useTransactionSettingsStore((state) => state.slippage)
  const priorityFee = useTransactionSettingsStore((state) => state.priorityFee)

  const [expectAmount, setExpectAmount] = useState<string | null>(null)
  const [sellAmount, setSellAmount] = useState<string | null>('0')
  const updateSellAmount = useCallback((amount: string | null) => {
    setSellAmount(amount)
  }, [])

  // const isInsufficientBalance = tokenBalance.lte(0) || tokenBalance.lt(sellAmount ?? 0)
  const isInsufficientBalance = Number(tokenBalance) <= 0 || Number(sellAmount) <= 0
  // const isInsufficientBalance = false
  const validSellAmount = pairAddress && !isInsufficientBalance

  const [selling, setSelling] = useState(false)

  const exchangeConfirm = trpc.exchangeConfirmTransaction.useMutation()
  const exchangeSell = trpc.exchangeSwap.useMutation()
  const exchangePreflight = trpc.exchangePreflight.useMutation()

  const confirmSell = async (options?: { preflight?: boolean }): Promise<void> => {
    if (selling) return

    // 1. 前端校验
    if (!pairAddress) {
      alert(t(`routes.pair.transaction.error.default`))
      return
    }
    if (isInsufficientBalance) {
      alert(t(`routes.pair.transaction.error.insufficientBalance`))
      return
    }

    const amount = sellAmount
    const percent = Number(((Number(amount) / Number(tokenBalance)) * 100).toFixed(DECIMALS))

    const params: SwapInputWithPair = {
      tradeType: 'in',
      percent,
      slippage: slippage === 'Auto' ? { type: 'auto' } : { type: 'constant', value: Math.round(slippage * 10) },
      priorityFeeLamports: Math.round(priorityFee * LAMPORTS_PER_SOL),
      pair: pairAddress,
      amount: fromExponential(amount ?? 0)
    }

    const onTerminal = (): void => {
      sellCustomModal.close()
      setSellAmount('0')
      setSelling(false)
      setExpectAmount(null)
    }

    // 2. preflight
    if (options?.preflight) {
      try {
        const data = await exchangePreflight.mutateAsync(params)
        setExpectAmount(data.minAmountOut)
        if (Number(data.minAmountOut) <= 0) {
          throw new Error(t(`routes.pair.transaction.error.insufficientBalance`))
        }
      } catch (error) {
        // @ts-expect-error 错误类型不可知
        alert(error?.message ?? t(`routes.pair.transaction.error.default`))
        console.error(error)
        captureException(error)
        return
      }

      return
    }

    setSelling(true)

    try {
      // 3. swap
      const response = await exchangeSell.mutateAsync(params)
      toast.info(t(`routes.pair.transaction.sent.message`))
      onTerminal()

      // 4. confirm
      await exchangeConfirm.mutateAsync({
        maskedExchangeRecordId: response.recordId,
        transactionId: response.transactionId
      })

      utils.addressBalance.invalidate()
      utils.addressTokenAccounts.invalidate()

      // 由于 confirm 的时机比较早，余额不一定正确更新了，多触发两次
      setTimeout(() => {
        utils.addressBalance.invalidate()
        utils.addressTokenAccounts.invalidate()
      }, 3000)

      setTimeout(() => {
        utils.addressBalance.invalidate()
        utils.addressTokenAccounts.invalidate()
      }, 6000)

      toast.success(t(`routes.pair.transaction.success.message`))
    } catch (error) {
      onTerminal()
      toast.error(t(`routes.pair.transaction.failed.message`))
      console.error(error)
      captureException(error)
    }
  }

  const debouncedPreflight = useDebounce(confirmSell)

  useEffect(() => {
    setExpectAmount(null)
    if (sellAmount && Number(sellAmount) > 0) {
      debouncedPreflight()
    }
    // 不需要 check debounce 方法
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellAmount])

  return (
    <>
      <HStack w="100%" justifyContent="space-between" gap="20px">
        <Wrap flexWrap="nowrap" gap="8px" alignItems="center" alignSelf="flex-start" color="#ffffff" fontSize="16px">
          <Divider thickness="2px" color="rgba(255, 122, 0, 1)" h="15px" orientation="vertical" />
          {t(`routes.pair.transaction.sell.label`)}
        </Wrap>
        <Grid columns={3} gap="8px" flex="1">
          {RANGE_SELL_PERCENTAGE.map((item) => (
            <GridItem
              onClick={() => {
                if (!isWalletConnect) {
                  connectWalletModal.open()
                  return
                }
                // js number 会丢失精度，所以 100% 的时候要直接取 token balance
                // 其他情况下会丢失精度，但是能接受
                updateSellAmount(item === 1 ? tokenBalance : (Number(tokenBalance) * item).toString())
                sellConfirmModal.open()
              }}
              key={item}
              px="9.5px"
              h="37px"
              bgColor="#38312E"
              border="1px solid rgba(255, 122, 0, 0.22)"
              rounded="4px">
              <Flex
                h="100%"
                w="100%"
                alignItems="center"
                justifyContent="center"
                color="rgba(255, 122, 0, 1)"
                fontSize="14px"
                fontWeight="510">
                {screenWidth > 390 && t(`routes.pair.transaction.sell.label`)} {`${item * 100}%`}
              </Flex>
            </GridItem>
          ))}
          <GridItem
            onClick={() => {
              if (!isWalletConnect) {
                connectWalletModal.open()
                return
              }
              sellCustomModal.open()
            }}
            px="9.5px"
            h="37px"
            bgColor="#22232B"
            border="1px solid rgba(255, 122, 0, 0.22)"
            rounded="4px">
            <Flex
              h="100%"
              w="100%"
              alignItems="center"
              justifyContent="center"
              color="rgba(255, 122, 0, 1)"
              fontSize="14px"
              fontWeight="510">
              {t(`routes.pair.transaction.sell.custom.label`)}
            </Flex>
          </GridItem>
        </Grid>
      </HStack>

      <Modal modalId={sellCustomModal.modalId} closeOnClickOverlay={!selling}>
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          gap="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)">
          <Wrap
            gap="8px"
            w="100%"
            alignItems="center"
            textAlign="left"
            fontSize="16px"
            fontWeight="510"
            color="#FFFFFF"
            textTransform="capitalize">
            <Divider orientation="vertical" color="#FF7A00" thickness="2px" h="15px" />
            {t(`routes.pair.transaction.sell.label`)} {token.name}
          </Wrap>
          <Wrap gap="2px" w="100%" justifyContent="flex-start" alignItems="center" fontSize="14px" fontWeight="400">
            <Box color="rgba(255, 255, 255, 0.4)" fontWeight="400">
              {t(`routes.pair.modal.sellCustom.balance.label`)}:&nbsp;&nbsp;
            </Box>
            <Box fontWeight="400" color="#FFFFFF">
              {tokenBalance.toString()}
            </Box>
            <Box color="rgba(255, 255, 255, 0.4)">{token.symbol}</Box>
          </Wrap>
          <Slider value={sellAmount} onChange={updateSellAmount} unit={token.symbol} />
          {expectAmount !== null && (
            <Box color="#FFFFFF" fontSize="16px" fontWeight="510">
              ≈ {expectAmount} SOL
            </Box>
          )}
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="4"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#575862"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={() => {
                if (!selling) sellCustomModal.close()
              }}>
              {t(`routes.pair.modal.sellCustom.cancel.label`)}
            </HStack>
            <HStack
              justifyContent="center"
              flex="11"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#FF7A00"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              _disabled={{
                color: '#FFFFFF',
                background: '#575862'
              }}
              {...createDataset({
                'data-disabled': !validSellAmount
              })}
              onClick={async () => {
                await confirmSell()
              }}>
              {selling && (
                <>
                  <Spinner color="rgba(0, 0, 0, 1)" size={24} />
                </>
              )}
              {!selling && !isInsufficientBalance && t(`routes.pair.modal.sellCustom.confirm.label`)}
              {!selling && isInsufficientBalance && t(`routes.pair.transaction.error.insufficientBalance`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>

      <Modal modalId={sellConfirmModal.modalId} closeOnClickOverlay={!selling}>
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          gap="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)">
          <Wrap
            gap="8px"
            w="100%"
            alignItems="center"
            textAlign="left"
            fontSize="16px"
            fontWeight="510"
            color="#FFFFFF"
            textTransform="capitalize">
            <Divider orientation="vertical" color="#FF7A00" thickness="2px" h="15px" />
            {t(`routes.pair.transaction.sell.label`)} {token.name}
          </Wrap>
          <Wrap alignItems="flex-end" gap="4px" color="#FF7A00" fontSize="32px" fontWeight="600" wordBreak="break-all">
            {sellAmount}
            <Box color="rgba(255, 255, 255, 0.20)" fontSize="24px">
              {token.symbol}
            </Box>
          </Wrap>
          {expectAmount !== null && (
            <Box color="#FFFFFF" fontSize="16px" fontWeight="510">
              ≈ {expectAmount} SOL
            </Box>
          )}
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="4"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#575862"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={() => {
                if (!selling) sellConfirmModal.close()
              }}>
              {t(`routes.pair.modal.sellConfirm.cancel.label`)}
            </HStack>
            <HStack
              justifyContent="center"
              flex="11"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#FF7A00"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              {...createDataset({
                'data-disabled': !validSellAmount
              })}
              _disabled={{
                color: '#FFFFFF',
                background: '#575862'
              }}
              onClick={async () => {
                await confirmSell()
              }}>
              {selling && (
                <>
                  <Spinner color="rgba(0, 0, 0, 1)" size={24} />
                </>
              )}
              {!selling && !isInsufficientBalance && t(`routes.pair.modal.sellConfirm.confirm.label`)}
              {!selling && isInsufficientBalance && t(`routes.pair.transaction.error.insufficientBalance`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>
    </>
  )
}
