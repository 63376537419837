import 'virtual:svg-icons-register'
import 'react-toastify/dist/ReactToastify.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './setup.css'
import '@/initializers/globalStyle.css'
import { RouterProvider } from '@tanstack/react-router'
import { QueryClientProvider } from '@tanstack/react-query'
import { initDebugger, initI18n, initMonitor, router } from '@/initializers'
import { HelmetProvider } from 'react-helmet-async'
import { trpc, tRpcClient, queryClient, usePlatformStore, WalletAdapterProvider } from './stores'

initDebugger()
initMonitor()
initI18n()

const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <StrictMode>
      <trpc.Provider client={tRpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <WalletAdapterProvider>
              <RouterProvider router={router} />
            </WalletAdapterProvider>
          </HelmetProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </StrictMode>
  )

  if (usePlatformStore.getState().current === 'telegram-web') {
    window.Telegram.WebApp.ready()
  }
}
