import type { FC } from 'react'
import BoringAvatar from 'boring-avatars'
import { clsx } from '../index'
import { avatar } from './avatar.css'

export interface AvatarProps {
  className?: string
  size: number
  name: string
}

export const Avatar: FC<AvatarProps> = ({ name, size, className }) => {
  return (
    <span className={clsx(avatar, className)}>
      <BoringAvatar
        square={true}
        name={name}
        size={size}
        variant="beam"
        colors={['#0A0310', '#3247C4', '#FF8599', '#FF7D10', '#FFB238']}
      />
    </span>
  )
}
