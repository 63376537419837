import type { FC } from 'react'
import { MoonLoader } from 'react-spinners'

export interface SpinnerProps {
  size?: number
  color?: string
  className?: string
}

export const Spinner: FC<SpinnerProps> = ({ size, className, color = '#000000' }) => (
  <MoonLoader className={className} size={size} color={color} />
)
