/* eslint-disable */
// @ts-nocheck

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`
  return {
    short,
    full: `${exchange}:${short}`
  }
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    return null
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] }
}

const RESOLUTION_MAPPING = {
  '1': '1m',
  '3': '3m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '60': '1H',
  '120': '2H',
  '240': '4H',
  '1D': '1D',
  '1W': '1W'
}

export function parseResolution(resolution) {
  if (!resolution || !RESOLUTION_MAPPING[resolution]) return RESOLUTION_MAPPING[0]

  return RESOLUTION_MAPPING[resolution]
}

/* eslint-enable */
