import { create } from 'zustand'

export const useSearchModeStore = create<{
  searchMode: boolean
  show: () => void
  hide: () => void
}>((set) => ({
  searchMode: false,
  show: (): void => set({ searchMode: true }),
  hide: (): void => set({ searchMode: false })
}))
