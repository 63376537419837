import { LAMPORTS_PER_SOL } from '@/constant'
import { css } from '@/styled-system/css'
import { Box, VStack, HStack, Wrap, Divider } from '@/styled-system/jsx'
import { createDataset, numberAbbr } from '@/utils'
import { getSymbolOfChain } from '@/utils/web3'
import { Icon } from '@turbx/deprecated-design-system'
import { t } from 'i18next'
import type { FC } from 'react'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedTokenWithPrice = any

export interface InformationProps {
  token: NormalizedTokenWithPrice
  balance: number | undefined
  tokenBalance: string | undefined
  renounced: boolean | undefined
  burned: boolean | undefined
  supply: number | null | undefined
}

export const Information: FC<InformationProps> = ({ token, balance, tokenBalance, burned, renounced, supply }) => {
  const chainSymbol = getSymbolOfChain(token)
  const marketCap = token.price?.value !== undefined && supply !== undefined ? token.price.value * Number(supply) : null

  return (
    <VStack w="100%" gap="0">
      <HStack flexWrap="wrap" justifyContent="space-between" w="100%" gap="0">
        <HStack gap="8px" color="rgba(255, 255, 255, 0.4)" fontSize="14px" fontWeight="400" h="45px">
          <Box>{t(`routes.pair.detail.marketCap.label`)}:</Box>
          <Wrap gap="2px">
            {marketCap !== null && (
              <>
                <Box color="rgba(255, 255, 255, 0.3)" fontWeight="600">
                  $
                </Box>
                <Box fontWeight="600" color="#ffffff">
                  {numberAbbr(marketCap)}
                </Box>
              </>
            )}
          </Wrap>
        </HStack>
        <Wrap h="45px" alignItems="center">
          <Wrap
            {...createDataset({
              'data-disabled': !renounced
            })}
            alignItems="center"
            gap="4px"
            px="8px"
            h="28px"
            color="#ffffff"
            _disabled={{
              color: `rgba(255, 255, 255, 0.4)`,
              bgColor: `rgba(255, 255, 255, 0.10)`,
              borderColor: `rgba(255, 255, 255, 0.40)`
            }}
            fontSize="12px"
            fontWeight="510"
            rounded="61px"
            bgColor="rgba(0, 163, 255, 0.20)"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="#00E0FF">
            {t(`routes.pair.detail.renounced.label`)}
            {renounced ? (
              <Icon name="check" className={css({ color: 'rgba(0, 255, 255, 1)', w: '16px', h: '16px' })} />
            ) : (
              <Icon name="none" className={css({ color: 'rgba(255, 255, 255, 0.40)', w: '16px', h: '16px' })} />
            )}
          </Wrap>
          <Wrap
            {...createDataset({
              'data-disabled': !burned
            })}
            alignItems="center"
            gap="4px"
            px="8px"
            h="28px"
            color="#ffffff"
            _disabled={{
              color: `rgba(255, 255, 255, 0.4)`,
              bg: `rgba(255, 255, 255, 0.1)`,
              borderColor: `rgba(255, 255, 255, 0.40)`
            }}
            fontSize="12px"
            fontWeight="510"
            rounded="61px"
            bg="linear-gradient(143deg, rgba(255, 61, 0, 0.20) 8.29%, rgba(255, 214, 0, 0.20) 97.53%)"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="#FFC700">
            {t(`routes.pair.detail.burned.label`)}
            {burned ? (
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M8 15.8333C5.23857 15.8333 3 13.5947 3 10.8333C3 9.39747 3.60524 8.103 4.57453 7.19107C5.46935 6.34917 7.66667 4.83301 7.33333 1.5C11.3333 4.16667 13.3333 6.83333 9.33333 10.8333C10 10.8333 11 10.8333 12.6667 9.1864C12.8465 9.70213 13 10.2563 13 10.8333C13 13.5947 10.7614 15.8333 8 15.8333Z"
                    fill="url(#paint0_linear_364_27381)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_364_27381"
                      x1="8"
                      y1="15.8333"
                      x2="8"
                      y2="1.5"
                      gradientUnits="userSpaceOnUse">
                      <stop stopColor="#FFA800" />
                      <stop offset="1" stopColor="#FF4A4A" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
            ) : (
              <Icon name="none" className={css({ color: 'rgba(255, 255, 255, 0.40)', w: '16px', h: '16px' })} />
            )}
          </Wrap>
        </Wrap>
      </HStack>
      <Divider thickness="0.5px" color="rgba(255, 255, 255, 0.2)" />
      <HStack
        width="100%"
        h="45px"
        gap="0"
        color="rgba(255, 255, 255, 0.4)"
        fontSize="14px"
        fontWeight="400"
        flexWrap="wrap">
        <HStack gap="0">
          <Box>{t(`routes.pair.detail.balance.label`)}:</Box>
          <HStack gap="3px" justifyContent="flex-end" flex="1">
            <Box fontWeight="600" color="#72E3B4" overflow="hidden" textOverflow="ellipsis" flex="1" textAlign="right">
              {balance !== undefined && balance / LAMPORTS_PER_SOL}
            </Box>
            <Box fontWeight="600">{chainSymbol}</Box>
          </HStack>
          <Divider mx="24px" orientation="vertical" color="rgba(255, 255, 255, 0.4)" thickness="0.4px" height="14px" />
        </HStack>
        <HStack gap="0">
          <HStack gap="3px" justifyContent="flex-end" flex="1">
            <Box flex="1" textAlign="right" fontWeight="600" color="#72E3B4" overflow="hidden" textOverflow="ellipsis">
              {tokenBalance !== undefined && tokenBalance}
            </Box>
            <Box fontWeight="600">{token.symbol}</Box>
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  )
}
