import { APP_ENVIRONMENT, SENTRY_DSN, SERVER_API_ENDPOINT } from '@/constant'
import { init, browserTracingIntegration, replayIntegration } from '@sentry/react'

export const initMonitor = (): void => {
  if (!SENTRY_DSN) return

  init({
    dsn: SENTRY_DSN,
    environment: APP_ENVIRONMENT,
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [new RegExp(`^${SERVER_API_ENDPOINT}`)],
    // Session Replay
    replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
