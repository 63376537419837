import {
  TokenAvatar,
  connectWalletModalId,
  useModal,
  useNotificationStore,
  useSearchModeStore
} from '@/deprecated-components'
import { router } from '@/initializers'
import { trpc } from '@/stores'
import { css } from '@/styled-system/css'
import { HStack, VStack, Box } from '@/styled-system/jsx'
import { copy, createDataset } from '@/utils'
import { getSymbolOfChain } from '@/utils/web3'
import { Icon } from '@turbx/deprecated-design-system'
import { useCallback, useEffect, useState, type FC } from 'react'
import { captureException } from '@sentry/react'
import { useTranslation } from 'react-i18next'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedPair = any

export interface TopBarProps {
  isWalletConnect: boolean
  pair: NormalizedPair
}

export const TopBar: FC<TopBarProps> = ({ pair, isWalletConnect }) => {
  const { t } = useTranslation()
  const alert = useNotificationStore((state) => state.alert)

  const copyTokenAddress = useCallback(() => {
    copy(pair.pair)
    alert(t(`common.copyToast`))
  }, [alert, t, pair.pair])

  const chainSymbol = getSymbolOfChain(pair.token)
  const showSearchMode = useSearchModeStore((state) => state.show)
  const connectWalletModal = useModal(connectWalletModalId)

  const utils = trpc.useUtils()
  const createFavorite = trpc.favoriteCreate.useMutation()
  const deleteFavorite = trpc.favoriteDelete.useMutation()
  const { data: favorites } = trpc.favoritesList.useQuery()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const favorite = favorites?.find((i: any) => i.pair.pair === pair?.pair)?.favorite
  const [isFavorite, setIsFavorite] = useState(!!favorite)
  useEffect(() => {
    setIsFavorite(!!favorite)
  }, [favorite])
  const [isLoading, setIsLoading] = useState(false)

  const toggleFavorite = async (): Promise<void> => {
    if (isLoading) return
    setIsLoading(true)
    setIsFavorite(!favorite)
    try {
      if (favorite) await deleteFavorite.mutateAsync({ maskedFavoriteId: favorite.id })
      else await createFavorite.mutateAsync({ pair: pair.pair })
      utils.favoritesList.invalidate()
    } catch (error) {
      console.error(error)
      captureException(error)
    }
    setIsLoading(false)
  }

  return (
    <HStack bg="#22232B" pos="sticky" top="0" left="0" zIndex="2" p="14px 24px" gap="0">
      <Box
        me="12px"
        onClick={() => {
          if (router.history.location.state.key) {
            router.history.back()
          } else {
            router.navigate({
              to: '/'
            })
          }
        }}>
        <Icon
          name="arrow-left"
          className={css({
            color: '#ffffff',
            w: '24px',
            h: '24px'
          })}
        />
      </Box>
      <HStack gap="0" justifyContent="space-between">
        <HStack gap="12px">
          <TokenAvatar heat={pair.popularity} avatar={pair.token.logo} dark={true} />
          <VStack gap="2px" alignItems="flex-start">
            <HStack onClick={copyTokenAddress} gap="2px">
              <Box
                color="#ffffff"
                fontSize="14px"
                fontWeight="510"
                maxW="145px"
                css={{
                  '@media (max-width: 375px)': {
                    maxW: '110px'
                  }
                }}
                overflow="hidden"
                textWrap="nowrap"
                textOverflow="ellipsis">
                {pair.token.name}
              </Box>
              <Icon
                name="copy"
                className={css({
                  w: '12px',
                  h: '12px',
                  color: 'rgba(255, 255, 255, 0.4)'
                })}
              />
            </HStack>
            <Box color="#ffffff" fontSize="12px" fontWeight="510">
              {pair.token.symbol}
              <Box display="inline" color="rgba(255, 255, 255, 0.40)">
                {' '}
                / {chainSymbol}
              </Box>
            </Box>
          </VStack>
        </HStack>
      </HStack>
      <HStack gap="0" flex="1" justifyContent="flex-end">
        {/* <Box p="10px">
                  <Icon className={css({ cursor: 'pointer', color: '#ffffff', w: '24px', h: '24px' })} name="share" />
                </Box> */}
        <Box
          p="10px"
          onClick={() => {
            if (!isWalletConnect) {
              connectWalletModal.open()
              return
            }
            toggleFavorite()
          }}>
          <Icon
            {...createDataset({
              'data-active': isFavorite
            })}
            className={css({
              cursor: 'pointer',
              color: '#ffffff',
              w: '24px',
              h: '24px',
              _active: {
                color: 'rgba(255, 168, 0, 1)'
              }
            })}
            name="star-fill"
          />
        </Box>
        <Box
          p="10px"
          onClick={() => {
            showSearchMode()
          }}>
          <Icon className={css({ cursor: 'pointer', color: '#ffffff', w: '24px', h: '24px' })} name="search" />
        </Box>
      </HStack>
    </HStack>
  )
}
