import { Modal, connectWalletModalId, useModal } from '@/deprecated-components'
import { usePlatformStore } from '@/stores'
import { css } from '@/styled-system/css'
import { Box, VStack, HStack, Wrap, Divider } from '@/styled-system/jsx'
import { createDataset, isFloatNumberEq } from '@/utils'
import { getSymbolOfChain } from '@/utils/web3'
import { Icon, motion } from '@turbx/deprecated-design-system'
import { t } from 'i18next'
import { useRef, useState, type FC } from 'react'
import {
  useTransactionSettingsStore,
  DEFAULT_CUSTOM_SLIPPAGE,
  RANGE_PRIORITY_FEE,
  MIN_SLIPPAGE,
  MAX_SLIPPAGE
} from './transaction-settings.store'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedToken = any

export interface TransactionSettingsProps {
  isWalletConnect: boolean
  token: NormalizedToken
}

function normalizeSlippage(value: undefined | 'Auto' | number): string {
  if (value === undefined) return ''
  if (value === 'Auto') return value
  return `${value}%`
}

export const TransactionSettings: FC<TransactionSettingsProps> = ({ token, isWalletConnect }) => {
  const slippage = useTransactionSettingsStore((state) => state.slippage)
  const priorityFee = useTransactionSettingsStore((state) => state.priorityFee)

  const screenWidth = usePlatformStore((state) => state.dimensions.screenWidth)
  const [settingsExpanded, setSettingsExpanded] = useState(false)
  const chainSymbol = getSymbolOfChain(token)

  const slippageInputRef = useRef<HTMLInputElement>(null)
  const priorityFeeInputRef = useRef<HTMLInputElement>(null)

  const slippageModal = useModal()
  const priorityFeeModal = useModal()
  const connectWalletModal = useModal(connectWalletModalId)

  return (
    <>
      <VStack gap="0" w="100%" p="12px 20px" rounded="24px" bgColor="#2E2F38">
        <HStack flex="1" gap="0" w="100%" onClick={() => setSettingsExpanded((expanded) => !expanded)}>
          <HStack gap="12px" flex="1">
            {/* <Icon
              name="setting2"
              className={css({
                w: '20px',
                h: '20px',
                color: 'rgba(255, 255, 255, 0.4)'
              })}
            /> */}
            <Wrap
              gap={screenWidth > 375 ? `12px` : `10px`}
              flex="1"
              color="rgba(255, 255, 255, 0.70)"
              fontSize="14px"
              fontWeight="400"
              alignItems="center">
              <Box>
                {t('routes.pair.transaction.settings.slippage.label')}
                &nbsp;&nbsp;{normalizeSlippage(slippage)}
              </Box>
              <Divider orientation="vertical" color="rgba(255, 255, 255, 0.4)" thickness="0.4px" height="14px" />
              <Box>
                {t('routes.pair.transaction.settings.priorityFee.label')}
                &nbsp;&nbsp;{priorityFee} {chainSymbol}
              </Box>
            </Wrap>
          </HStack>
          <motion.span
            transition={{
              ease: 'linear',
              duration: 0.2
            }}
            animate={{
              rotate: settingsExpanded ? 180 : 0
            }}>
            <Icon
              name="arrow-down"
              className={css({
                w: '20px',
                h: '20px',
                color: '#FFFFFF'
              })}
            />
          </motion.span>
        </HStack>
        <motion.div
          transition={{
            ease: 'linear',
            duration: 0.2
          }}
          initial={false}
          animate={{
            width: '100%',
            marginTop: settingsExpanded ? '12px' : '0',
            display: settingsExpanded ? 'block' : 'none',
            overflow: 'hidden',
            height: settingsExpanded ? 'auto' : 0
          }}>
          <VStack gap="12px" w="100%" py="12px">
            <HStack gap="12px" w="100%">
              <Box fontSize="12px" fontWeight="400" color="rgba(255, 255, 255, 0.70)">
                {t(`routes.pair.transaction.settings.slippage.label`)} %
              </Box>
              <Wrap gap="8px" flex="1">
                <HStack
                  {...createDataset({
                    'data-active': typeof slippage === 'number'
                  })}
                  gap="2px"
                  h="37px"
                  fontSize="14px"
                  fontWeight="510"
                  flex="1"
                  rounded="4px"
                  justifyContent="center"
                  border="1px solid rgba(255, 255, 255, 0.24)"
                  color="#FFFFFF"
                  _active={{ color: 'rgba(0, 0, 0, 1)' }}
                  background={{ _default: 'none', _active: 'rgba(255, 255, 255, 0.80)' }}
                  onClick={() => {
                    if (!isWalletConnect) {
                      connectWalletModal.open()
                      return
                    }
                    slippageModal.open()
                  }}>
                  {typeof slippage === 'number' ? slippage : DEFAULT_CUSTOM_SLIPPAGE}
                  <Icon width="14px" height="14px" color="rgba(0, 0, 0, 0.4)" name="edit" />
                </HStack>
                <HStack
                  {...createDataset({
                    'data-active': slippage === 'Auto'
                  })}
                  gap="4px"
                  h="37px"
                  fontSize="14px"
                  color="#FFFFFF"
                  _active={{
                    color: 'rgba(0, 0, 0, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 0.80)'
                  }}
                  fontWeight="510"
                  flex="1"
                  className="group"
                  rounded="4px"
                  justifyContent="center"
                  border="1px solid rgba(255, 255, 255, 0.24)"
                  backgroundColor="none"
                  onClick={async () => {
                    if (!isWalletConnect) {
                      connectWalletModal.open()
                      return
                    }
                    useTransactionSettingsStore.getState().updateSlippage('Auto')
                  }}>
                  {t(`routes.pair.transaction.settings.slippage.auto.label`)}
                </HStack>
              </Wrap>
            </HStack>
            <HStack gap="12px" w="100%">
              <Box fontSize="12px" fontWeight="400" color="rgba(255, 255, 255, 0.70)">
                {t(`routes.pair.transaction.settings.priorityFee.label`)}
              </Box>
              <Wrap gap="8px" flex="1">
                {RANGE_PRIORITY_FEE.map((item) => (
                  <HStack
                    {...createDataset({
                      'data-active': isFloatNumberEq(item, priorityFee)
                    })}
                    key={item}
                    color="#FFFFFF"
                    _active={{
                      color: 'rgba(0, 0, 0, 1)',
                      backgroundColor: 'rgba(255, 255, 255, 0.80)'
                    }}
                    fontSize="14px"
                    fontWeight="510"
                    flex="1"
                    h="37px"
                    justifyContent="center"
                    rounded="4px"
                    border="1px solid rgba(255, 255, 255, 0.24)"
                    onClick={() => {
                      if (!isWalletConnect) {
                        connectWalletModal.open()
                        return
                      }
                      useTransactionSettingsStore.setState({
                        priorityFee: item
                      })
                    }}>
                    {item}
                  </HStack>
                ))}
                <HStack
                  {...createDataset({
                    'data-active': !RANGE_PRIORITY_FEE.includes(priorityFee)
                  })}
                  _active={{
                    color: 'rgba(0, 0, 0, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 0.80)'
                  }}
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight="510"
                  flex="1"
                  h="37px"
                  gap="2px"
                  justifyContent="center"
                  rounded="4px"
                  border="1px solid rgba(255, 255, 255, 0.24)"
                  onClick={() => {
                    if (!isWalletConnect) {
                      connectWalletModal.open()
                      return
                    }
                    priorityFeeModal.open()
                  }}>
                  {RANGE_PRIORITY_FEE.includes(priorityFee) ? (
                    t(`routes.pair.transaction.settings.priorityFee.custom.label`)
                  ) : (
                    <>
                      {priorityFee}
                      <Icon width="14px" height="14px" color="rgba(0, 0, 0, 0.4)" name="edit" />
                    </>
                  )}
                </HStack>
              </Wrap>
            </HStack>
          </VStack>
        </motion.div>
      </VStack>

      <Modal modalId={slippageModal.modalId}>
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          gap="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)">
          <Wrap
            gap="8px"
            w="100%"
            alignItems="center"
            textAlign="left"
            fontSize="16px"
            fontWeight="510"
            color="#FFFFFF"
            textTransform="capitalize">
            <Divider orientation="vertical" color="#ADFF26" thickness="2px" h="15px" />
            {t(`routes.pair.transaction.settings.slippage.label`)} %
          </Wrap>
          <input
            type="number"
            ref={slippageInputRef}
            className={css({
              w: '100%',
              h: '54px',
              px: '24px',
              rounded: '46px',
              border: `1px solid rgba(173, 255, 38, 0.50)`,
              bgColor: `rgba(101, 102, 111, 0.51)`,
              caretColor: 'rgba(173, 255, 38, 1)',
              color: 'rgba(173, 255, 38, 1)',
              fontSize: '24px',
              fontWeight: 600
            })}
            defaultValue={typeof slippage === 'number' ? slippage : DEFAULT_CUSTOM_SLIPPAGE}
            min={MIN_SLIPPAGE}
            max={MAX_SLIPPAGE}
          />
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="1"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#575862"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={slippageModal.close}>
              {t(`routes.pair.modal.slippage.cancel.label`)}
            </HStack>
            <HStack
              justifyContent="center"
              flex="1"
              h="48px"
              rounded="8px"
              color="#000000"
              background="#ADFF26"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={() => {
                useTransactionSettingsStore
                  .getState()
                  .updateSlippage(Number(slippageInputRef.current?.value ?? DEFAULT_CUSTOM_SLIPPAGE))
                slippageModal.close()
              }}>
              {t(`routes.pair.modal.slippage.confirm.label`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>

      <Modal modalId={priorityFeeModal.modalId}>
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          gap="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)">
          <Wrap
            gap="8px"
            w="100%"
            alignItems="center"
            textAlign="left"
            fontSize="16px"
            fontWeight="510"
            color="#FFFFFF"
            textTransform="capitalize">
            <Divider orientation="vertical" color="#ADFF26" thickness="2px" h="15px" />
            {t(`routes.pair.transaction.settings.priorityFee.label`)}
          </Wrap>
          <input
            type="number"
            ref={priorityFeeInputRef}
            className={css({
              w: '100%',
              h: '54px',
              px: '24px',
              rounded: '46px',
              border: `1px solid rgba(173, 255, 38, 0.50)`,
              bgColor: `rgba(101, 102, 111, 0.51)`,
              caretColor: 'rgba(173, 255, 38, 1)',
              color: 'rgba(173, 255, 38, 1)',
              fontSize: '24px',
              fontWeight: 600
            })}
            defaultValue={priorityFee}
            min="0.001"
            max="1"
          />
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="1"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#575862"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={priorityFeeModal.close}>
              {t(`routes.pair.modal.priorityFee.cancel.label`)}
            </HStack>
            <HStack
              justifyContent="center"
              flex="1"
              h="48px"
              rounded="8px"
              color="#000000"
              background="#ADFF26"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={() => {
                useTransactionSettingsStore
                  .getState()
                  .updatePriorityFee(Number(priorityFeeInputRef.current?.value ?? priorityFee))
                priorityFeeModal.close()
              }}>
              {t(`routes.pair.modal.priorityFee.confirm.label`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>
    </>
  )
}
