import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import packageJson from '../package.json'
import { clusterApiUrl } from '@solana/web3.js'
import type { NFTMintPublicKeyType } from '@turbx/solana'

export const ENABLE_DEBUGGER = import.meta.env.VITE_ENABLE_DEBUGGER === 'true'

export const SERVER_API_ENDPOINT = import.meta.env.VITE_SERVER_API_ENDPOINT! as string

export const SOLEDGE_API_ENDPOINT = import.meta.env.VITE_SOLEDGE_API_ENDPOINT! as string

export const CLOUDLINK_API_ENDPOINT = import.meta.env.VITE_CLOUDLINK_API_ENDPOINT! as string

export const APP_VERSION = import.meta.env.VITE_APP_VERSION || (packageJson.version as string)

export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT as string

export const SOLANA_FRONTEND_HTTP_ENDPOINT =
  import.meta.env.VITE_SOLANA_FRONTEND_HTTP_ENDPOINT || (clusterApiUrl(WalletAdapterNetwork.Mainnet) as string)

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | undefined

export const LAMPORTS_PER_SOL = 1000000000

export const FEATURE_FLAGS = ((import.meta.env.VITE_FEATURE_FLAGS as string) ?? '').split(',')

export const FEATURE_WALLET_ENABLED = FEATURE_FLAGS.includes('WALLET')

export const KEYPAIR_DAPP_NACL_PUBLIC_KEY = import.meta.env.VITE_KEYPAIR_DAPP_NACL_PUBLIC_KEY as string

export const KEYPAIR_NFT_EARLY_ACCESS_PUBLIC_KEY = import.meta.env
  .VITE_KEYPAIR_NFT_EARLY_ACCESS_PUBLIC_KEY as NFTMintPublicKeyType

export const KEYPAIR_MINT_PAYER_PUBLIC_KEY = import.meta.env.VITE_KEYPAIR_MINT_PAYER_PUBLIC_KEY as string
