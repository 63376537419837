import { trpc, usePlatformStore } from '@/stores'
import { Icon, Spinner, clsx, motion } from '@turbx/deprecated-design-system'
import { useEffect, useRef, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TokenPanel, useNavbarStore } from '@/deprecated-components'
import * as styles from './search-panel.css'
import { useSearchModeStore } from './search-panel.store'
import { skipToken } from '@tanstack/query-core'
import { debounce } from '@turbx/active-support'
import { Link } from '@tanstack/react-router'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedPair = any

// const duration = 0.1

// TODO: 用 usePlatformStore 代替
const isDesktop = (windowWidth: number): boolean => windowWidth > 1024

const AutocompleteItem: FC<{ dark?: boolean; pair: NormalizedPair }> = ({ dark, pair }) => {
  const screenWidth = usePlatformStore((state) => state.dimensions.screenWidth)

  return (
    <Link
      to="/pairs/$pairAddress"
      params={{ pairAddress: pair.pair }}
      onClick={() => {
        useSearchModeStore.getState().hide()
      }}>
      <motion.button
        whileTap={{
          scale: 0.97
        }}
        style={{
          width: `${screenWidth - styles.paddingHorizontalValue * 2}px`
        }}
        className={clsx(dark && styles.searchAutocompleteItemDark, styles.searchAutocompleteItem)}>
        <TokenPanel dark={dark} pair={pair} token={pair.token} />
      </motion.button>
    </Link>
  )
}

export interface SearchPanelProps {
  theme?: 'dark' | 'light'
}

export const SearchPanel: FC<SearchPanelProps> = ({ theme }) => {
  const { t } = useTranslation()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const { searchMode, show, hide } = useSearchModeStore()
  const screenWidth = usePlatformStore((state) => state.dimensions.screenWidth)

  const isDark = theme === 'dark'

  const searchQuery = useRef('')
  const [searchText, setSearchText] = useState('')
  const setSearchQuery = debounce((value: string) => {
    setSearchText(value)
  }, 300)

  const { isLoading, data } = trpc.pairSearch.useQuery(searchText ? { query: searchText } : skipToken)

  useEffect(() => {
    if (searchMode) useNavbarStore.getState().hide()
    else {
      setSearchText('')
      if (searchInputRef.current) searchInputRef.current.value = ''
      useNavbarStore.getState().show()
    }
  }, [searchMode])

  return (
    <div
      className={clsx(styles.searchPanel, isDark && styles.dark)}
      // layout={true}
      style={{
        position: searchMode ? 'fixed' : 'relative',
        top: searchMode ? '0' : 'unset',
        bottom: searchMode ? '0' : 'unset',
        left: searchMode ? 0 : 'unset',
        right: searchMode ? 0 : 'unset',
        paddingTop: searchMode ? '24px' : 0
      }}>
      <div
        className={styles.searchInputBar}
        style={{
          width: `${screenWidth - styles.paddingHorizontalValue * 2}px`
        }}
        // transition={{ duration }}
        // layout={true}
      >
        <div
          onClick={() => {
            if (!isDesktop(screenWidth)) show()
          }}
          className={styles.searchInputContainer}>
          <input
            name="search"
            enterKeyHint="search"
            type="search"
            ref={searchInputRef}
            className={styles.searchInput}
            placeholder={t('routes.index.searchInput.placeholder')}
            onChange={(e) => (searchQuery.current = e.target.value)}
            onKeyDown={(e) => {
              if (!searchQuery.current) return
              if (e.key === 'Enter') {
                setSearchQuery(searchQuery.current)
                searchInputRef.current?.blur()
              }
            }}
          />
          {/* <AnimatePresence> */}
          {!searchMode && (
            <span
              style={{
                width: `${styles.searchInputButtonWidthValue}px`
              }}
              // initial={{ width: 0, scale: 0 }}
              // animate={{ width: `${styles.searchInputButtonWidthValue}px`, scale: 1 }}
              // exit={{ width: 0, scale: 0 }}
              // transition={{
              //   duration
              // }}
              className={styles.searchInputButton}
              onClick={() => searchInputRef.current?.focus()}>
              <Icon name="search" className={styles.searchIcon} />
            </span>
          )}
          {/* </AnimatePresence> */}
        </div>
        {/* <AnimatePresence> */}
        {searchMode && (
          <div
            // layout={true}
            style={{
              width: `${styles.closeSearchButtonSize}px`,
              marginLeft: styles.closeSearchButtonMarginLeft
            }}
            // initial={{ width: 0, marginLeft: 0 }}
            // animate={{
            //   width: `${styles.closeSearchButtonSize}px`,
            //   marginLeft: styles.closeSearchButtonMarginLeft
            // }}
            // exit={{ width: 0, marginLeft: 0 }}
            // transition={{ duration }}
            onClick={() => {
              hide()
              searchInputRef.current?.blur()
            }}
            className={styles.closeSearchButton}>
            <Icon className={styles.closeIcon} name="close" />
          </div>
        )}
        {/* </AnimatePresence> */}
      </div>
      {/* <AnimatePresence> */}
      {searchMode && (
        <div
          // layout={true}
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1, transition: { delay: duration + 0.3 } }}
          // exit={{ opacity: 0, transition: { delay: 0 } }}
          // transition={{
          //   duration
          // }}
          className={styles.searchAutocompleteList}>
          <span className={styles.searchingBar}>
            {isLoading && (
              <>
                <Spinner
                  color={isDark ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.60)'}
                  size={12}
                  className={styles.spinnerIcon}
                />
                <span className={clsx(styles.searchingText, isDark && styles.searchingTextDark)}>
                  {t(`common.searchMode.searchingText`)}
                </span>
              </>
            )}
            {!isLoading && data?.length === 0 && (
              <span className={clsx(styles.searchingText, isDark && styles.searchingTextDark)}>
                {t(`common.searchMode.notFoundText`)}
              </span>
            )}
          </span>
          {data?.map((item) => <AutocompleteItem key={item.base_mint} dark={isDark} pair={item} />)}
        </div>
      )}
      {/* </AnimatePresence> */}
    </div>
  )
}
