/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Circle, Divider, Float, HStack, VStack } from '@/styled-system/jsx'
import { uniqBy } from '@turbx/active-support'
import TextareaAutosize from 'react-textarea-autosize'
import { createDataset, intAbbr, relativeTime } from '@/utils'
import { produce } from 'immer'
import { AnimatePresence, Avatar, Icon, motion, useInView } from '@turbx/deprecated-design-system'
import { forwardRef, useEffect, useRef, useState, type FC, type ReactNode } from 'react'
import hotCommentSticker from '@/assets/hot-sticker.png?inline'
import { css } from '@/styled-system/css'
import { trpc } from '@/stores'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'
import noContentPlaceholderImage from '@/assets/no-content-placeholder.svg'
import { create } from 'zustand'
import { skipToken } from '@tanstack/query-core'
import { Modal, connectWalletModalId, useModal } from '@/deprecated-components'
import { captureException } from '@sentry/react'
import diamondIcon from '@/assets/diamond.png'

type NormalizedCommentType = any
type TokenPairType = any

export interface CommentListProps {
  pairAddress: TokenPairType
  totalSize: number
}

interface CommentListStoreProps {
  pageDirection: 'desc' | 'asc'
  perPage: number
  orderBy: 'review_comments.popularity desc' | ''
  pairAddress: TokenPairType | undefined
  setOrderBy: (orderBy: 'popular' | 'latest') => void
}

const perPage = 25

const useCommentListStore = create<CommentListStoreProps>((set) => ({
  pageDirection: 'desc',
  perPage,
  orderBy: 'review_comments.popularity desc',
  pairAddress: undefined,
  setOrderBy: (orderBy): void => set({ orderBy: orderBy === 'popular' ? 'review_comments.popularity desc' : '' })
}))

const LikeIcon: FC<{
  dislike?: boolean
  active?: boolean
}> = ({ dislike, active }) => {
  const activeColor = 'rgba(173, 255, 38, 1)'
  return (
    <svg
      style={{
        transform: dislike ? 'unset' : 'scale(1, -1)'
      }}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_911_7170)">
        <path
          opacity={active ? 0.3 : 0.8}
          d="M5.33332 11.5002L3.66666 9.8335L4.33332 3.16683H9.99999L13 3.8335L14 5.8335L14.6667 8.50016V10.5002L9.66666 10.8335L9.33332 11.1668V12.8335V14.5002L8.66666 14.8335L7.33332 14.5002L5.33332 11.5002Z"
          fill={active ? activeColor : '#22232B'}
        />
        <path
          d="M9.73319 11.1666H14C14.7364 11.1666 15.3333 10.5697 15.3333 9.8333V8.43036C15.3333 8.25623 15.2992 8.08376 15.2329 7.9227L13.17 2.91276C13.0671 2.66296 12.8237 2.49996 12.5535 2.49996H1.33332C0.965136 2.49996 0.666656 2.79843 0.666656 3.16663V9.8333C0.666656 10.2015 0.965136 10.4999 1.33332 10.4999H3.65455C3.87117 10.4999 4.07428 10.6052 4.1992 10.7822L7.83479 15.9326C7.92979 16.0672 8.10886 16.1122 8.25619 16.0385L9.46559 15.4338C10.1667 15.0833 10.5287 14.2915 10.3354 13.5319L9.73319 11.1666ZM4.66666 9.44163V3.8333H12.1071L14 8.43036V9.8333H9.73319C8.86339 9.8333 8.22652 10.6526 8.44106 11.4956L9.04326 13.8609C9.08192 14.0128 9.00952 14.1711 8.86926 14.2412L8.42852 14.4616L5.28849 10.0132C5.12189 9.77723 4.90893 9.58376 4.66666 9.44163ZM3.33332 9.16663H1.99999V3.8333H3.33332V9.16663Z"
          fill={active ? activeColor : 'rgba(255, 255, 255, 1)'}
        />
      </g>
      <defs>
        <clipPath id="clip0_911_7170">
          <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0 16.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

const CommentItem = forwardRef<
  HTMLDivElement,
  {
    comment: NormalizedCommentType
  }
>(({ comment, ...props }, ref) => {
  const utils = trpc.useUtils()
  const createReaction = trpc.reactionCreate.useMutation()
  const deleteReaction = trpc.reactionDelete.useMutation()
  const queryParams = useCommentListStore((state) => ({
    pageDirection: state.pageDirection,
    perPage: state.perPage,
    orderBy: state.orderBy,
    // pairAddress must exist in this situation
    pair: state.pairAddress!
  }))

  const connectWalletModal = useModal(connectWalletModalId)

  const toggleReaction = (type: 'Like' | 'Dislike') => async (): Promise<void> => {
    if (comment.reaction.type === 'NeedAddress') {
      connectWalletModal.open()
      return
    }
    if (comment.reaction.type === 'Like' || comment.reaction.type === 'Dislike') {
      await deleteReaction.mutateAsync({ maskedReactionId: comment.reaction.id })
      utils.commentsPageByToken.setInfiniteData(queryParams, (oldData: any) =>
        produce(oldData, (draft: any) => {
          const row = draft?.pages.flatMap((p: any) => p.rows).find((r: any) => r.id === comment.id)
          if (!row) return
          row.reaction.type = 'None'
          if (comment.reaction.type === 'Like') row.likes_count = Math.max(0, row.likes_count - 1)
          if (comment.reaction.type === 'Dislike') row.dislikes_count = Math.max(0, row.dislikes_count - 1)
        })
      )
    }

    if (comment.reaction.type !== type) {
      const reaction = await createReaction.mutateAsync({ maskedCommentId: comment.id, type })
      utils.commentsPageByToken.setInfiniteData(queryParams, (oldData: any) =>
        produce(oldData, (draft: any) => {
          const row = draft?.pages.flatMap((p: any) => p.rows).find((r: any) => r.id === comment.id)
          if (!row) return
          row.reaction = reaction
          if (type === 'Like') row.likes_count += 1
          if (type === 'Dislike') row.dislikes_count += 1
        })
      )
    }
  }

  return (
    <>
      <HStack ref={ref} w="100%" alignItems="flex-start" paddingTop="12px" paddingRight="12px" gap="14px" {...props}>
        <Circle overflow="hidden" w="36px" h="36px" border="1px solid #FFFFFF">
          <Avatar name={comment.address.address} size={36} />
        </Circle>
        <VStack flex="1" alignItems="flex-start" gap="8px">
          <HStack gap="8px">
            <Box
              maxW="100px"
              overflow="hidden"
              textOverflow="ellipsis"
              fontSize="14px"
              lineHeight="17px"
              fontWeight={590}
              color="rgba(255, 255, 255, 0.8)">
              {comment.address.address}
            </Box>
            {comment.address.accolades.find((i: any) => i.symbol === 'EarlyAccess') && (
              <img
                className={css({
                  width: '16px',
                  height: '16px',
                  marginLeft: '-5px'
                })}
                src={diamondIcon}
              />
            )}
            <Divider color="rgba(255, 255, 255, 0.2)" thickness="0.4px" h="12px" orientation="vertical" />
            <Box fontSize="14px" lineHeight="17px" fontWeight={590} color="rgba(255, 255, 255, 0.4)">
              {relativeTime(comment.createdAt)}
            </Box>
          </HStack>
          {comment.type === 'Text' && (
            <VStack
              pos="relative"
              w="100%"
              rounded="4px 20px 20px 20px"
              border="1px solid #000000"
              bgColor="#575862"
              boxShadow="0px 6px 0px 0px #060816"
              p="16px 20px"
              alignItems="flex-start"
              color="#FFFFFF"
              fontSize="14px"
              lineHeight="16px"
              fontWeight={400}
              wordBreak="break-all">
              {comment.isCommentHot && (
                <Float offsetX="32px">
                  <motion.img
                    className={css({
                      w: '45px'
                    })}
                    src={hotCommentSticker}
                    initial={{
                      y: 0
                    }}
                    animate={{
                      y: [2, -2]
                    }}
                    transition={{
                      repeat: Infinity,
                      type: 'spring',
                      repeatType: 'mirror',
                      duration: 0.4
                    }}
                  />
                </Float>
              )}
              {comment.content.body}
            </VStack>
          )}
          <HStack alignSelf="flex-end" p="8px">
            <HStack gap="4px" color="rgba(255, 255, 255, 0.8)" fontSize="14px" onClick={toggleReaction('Dislike')}>
              <LikeIcon active={comment.reaction.type === 'Dislike'} dislike={true} />
              <Box
                {...createDataset({
                  'data-active': comment.reaction.type === 'Dislike'
                })}
                _active={{
                  color: 'rgba(173, 255, 38, 1)'
                }}>
                {intAbbr(comment.dislikes_count)}
              </Box>
            </HStack>
            <Divider color="rgba(255, 255, 255, 0.2)" thickness="0.4px" h="12px" orientation="vertical" />
            <HStack gap="4px" color="rgba(255, 255, 255, 0.8)" fontSize="14px" onClick={toggleReaction('Like')}>
              <LikeIcon active={comment.reaction.type === 'Like'} />
              <Box
                {...createDataset({
                  'data-active': comment.reaction.type === 'Like'
                })}
                _active={{
                  color: 'rgba(173, 255, 38, 1)'
                }}>
                {intAbbr(comment.likes_count)}
              </Box>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
    </>
  )
})

export const CommentList: FC<CommentListProps> = ({ pairAddress, totalSize }) => {
  const { t } = useTranslation()
  const listRef = useRef<HTMLDivElement>(null)

  const utils = trpc.useUtils()

  useEffect(() => {
    useCommentListStore.setState({ pairAddress })
  }, [pairAddress])

  const queryParams = useCommentListStore((state) => ({
    pageDirection: state.pageDirection,
    perPage: state.perPage,
    orderBy: state.orderBy
  }))

  const setOrderBy = useCommentListStore((state) => state.setOrderBy)

  const response = trpc.commentsPageByToken.useInfiniteQuery(
    pairAddress ? { ...queryParams, pair: pairAddress } : skipToken,
    {
      getNextPageParam: (lastPage: any) => {
        const lastComment = lastPage.rows[lastPage.rows.length - 1]
        return lastComment
          ? { maskedCommentId: lastComment.id, direction: 'asc', popularity: lastComment.popularity }
          : undefined
      },
      refetchOnMount: 'always'
    }
  )

  const comments = uniqBy(response.data?.pages.flatMap((p: any) => p.rows) ?? [], 'id')

  const lanes = 1
  const virtualizer = useWindowVirtualizer({
    count: response.hasNextPage ? comments.length + 1 : comments.length,
    estimateSize: (index) => {
      const item = comments[index] as any
      const baseSize = 120
      if (!item) return 40

      if (item.type !== 'Text') return baseSize

      return baseSize + Math.ceil(item.content.body.length / 35) * 16
    },
    overscan: perPage,
    scrollMargin: listRef.current?.offsetTop ?? 0,
    lanes
  })

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (lastItem.index >= comments.length - 1 && response.hasNextPage && !response.isFetchingNextPage) {
      response.fetchNextPage()
    }
  }, [
    response.hasNextPage,
    response.fetchNextPage,
    comments.length,
    response.isFetchingNextPage,
    virtualizer,
    response
  ])

  useEffect(() => {
    if (response.isFetched) response.refetch()
    // 只有在 orderBy 变化的情况下才 refetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.orderBy])

  const createCommentModal = useModal()
  const connectWalletModal = useModal(connectWalletModalId)
  const isConnectWallet = utils.addressQueryCurrent.getData()?.type === 'completed'

  const [sending, setSending] = useState(false)

  const [newComment, setNewComment] = useState('')
  const createComment = trpc.commentCreate.useMutation()

  const createNewComment = async (): Promise<void> => {
    if (sending) return
    if (!newComment) return
    try {
      setSending(true)
      const newCommentItem = await createComment.mutateAsync({
        pair: pairAddress,
        comment: {
          type: 'Text',
          content: {
            body: newComment
          }
        }
      })
      setNewComment('')
      utils.pairInfoSimple.setData({ pair: pairAddress }, (oldData: any) => {
        if (!oldData) return oldData

        return {
          ...oldData,
          comments_count: oldData.comments_count + 1
        }
      })
      utils.commentsPageByToken.setInfiniteData({ ...queryParams, pair: pairAddress }, (oldData: any) =>
        produce(oldData, (draft: any) => {
          draft?.pages[0]?.rows.unshift(newCommentItem)
        })
      )

      createCommentModal.close()
      setTimeout(() => {
        virtualizer.scrollToIndex(0, { align: 'center' })
      }, 150)
    } catch (error) {
      console.error(error)
      captureException(error)
    }

    setSending(false)
  }

  const isCommentListVisible = useInView(listRef, { margin: '-50px' })

  return (
    <>
      <AnimatePresence>
        {!response.isLoading && isCommentListVisible && (
          <motion.div
            whileTap={{
              scale: 0.97
            }}
            initial={{
              x: '200%',
              opacity: 0
            }}
            animate={{
              x: 0,
              opacity: 1
            }}
            exit={{
              x: '200%',
              width: 0,
              opacity: 0
            }}
            transition={{
              delay: 0.25,
              duration: 0.2,
              type: 'spring'
            }}
            className={css({
              pos: 'fixed',
              right: '32px',
              bottom: '48px',
              zIndex: 9
            })}>
            <HStack
              gap="4px"
              bgColor="#426CFF"
              boxShadow="0px 4px 16px 0px rgba(41, 127, 255, 0.50)"
              p="10px 24px"
              rounded="37px"
              zIndex="9"
              fontWeight={590}
              fontSize="14px"
              onClick={() => {
                if (!isConnectWallet) {
                  connectWalletModal.open()
                  return
                }
                createCommentModal.open()
              }}>
              <Icon name="edit" className={css({ w: '18px', h: '18px', color: '#FFFFFF' })} />
              <Box color="#FFFFFF">{t(`routes.pair.comments.sender.button.label`)}</Box>
            </HStack>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!response.isLoading && !isCommentListVisible && (
          <motion.div
            whileTap={{
              scale: 0.97
            }}
            initial={{
              x: '200%',
              opacity: 0
            }}
            animate={{
              x: 0,
              opacity: 1
            }}
            exit={{
              x: '200%',
              opacity: 0
            }}
            transition={{
              delay: 0.25,
              duration: 0.2,
              type: 'spring'
            }}
            className={css({
              pos: 'fixed',
              right: '32px',
              bottom: '48px',
              zIndex: 9
            })}>
            <HStack
              gap="4px"
              bgColor="#ADFF26"
              boxShadow="0px 4px 16px 0px rgba(173, 255, 38, 0.40)"
              p="10px 24px"
              rounded="37px"
              fontWeight={590}
              fontSize="14px"
              onClick={() => {
                if (comments.length === 0) {
                  listRef.current?.scrollIntoView()
                } else {
                  virtualizer.scrollToIndex(0, { align: 'center' })
                }
              }}>
              <Icon name="chat" className={css({ w: '18px', h: '18px' })} color="#000000" />
              <Box color="#000000">{totalSize}</Box>
            </HStack>
          </motion.div>
        )}
      </AnimatePresence>
      <VStack bgColor="#2E2F38" roundedTop="24px" px="16px" gap="0" paddingBottom="100px" minHeight="379px">
        <HStack w="100%" p="24px 12px" gap="0" justifyContent="space-between">
          <HStack gap="12px" bgColor="#2E2F38" rounded="24px">
            <Box fontSize="20px" fontWeight={510} lineHeight="16px" color="#FFFFFF">
              {t(`routes.pair.comments.heading`)}
            </Box>
            <Box lineHeight="16px" color="rgba(255, 255, 255, 0.4)">
              {totalSize}
            </Box>
          </HStack>
          <HStack fontWeight="510" fontSize="14px" lineHeight="16px" gap="16px" color="rgba(255, 255, 255, 0.3)">
            <Box
              {...createDataset({
                'data-active': queryParams.orderBy === ''
              })}
              onClick={() => {
                setOrderBy('latest')
              }}
              _active={{
                color: '#FFFFFF'
              }}>
              {t(`routes.pair.comments.orderBy.latest.label`)}
            </Box>
            <Box
              {...createDataset({
                'data-active': queryParams.orderBy === 'review_comments.popularity desc'
              })}
              onClick={() => {
                setOrderBy('popular')
              }}
              _active={{
                color: '#FFFFFF'
              }}>
              {t(`routes.pair.comments.orderBy.popular.label`)}
            </Box>
          </HStack>
        </HStack>
        <Divider color="rgba(255, 255, 255, 0.2)" thickness="0.4" />
        <VStack w="100%" py="12px" gap="0" ref={listRef}>
          {comments.length === 0 && !response.isLoading && (
            <VStack gap="32px" marginY="120px">
              <img src={noContentPlaceholderImage} />
              <Box fontWeight="400" lineHeight="17px" fontSize="14px" color="rgba(255, 255, 255, 0.4)">
                {t(`common.infinityLoader.noContentText`)}
              </Box>
            </VStack>
          )}
          <VStack
            w="100%"
            gap="0"
            pos="relative"
            style={{
              height: `${virtualizer.getTotalSize()}px`
            }}>
            {virtualizer.getVirtualItems().map((item) => {
              const isLoader = item.index > comments.length - 1
              const comment = comments[item.index]!

              let content: ReactNode = null

              if (isLoader && response.hasNextPage)
                content = (
                  <Box w="100%" textAlign="center" color="#FFFFFF">
                    {t(`common.infinityLoader.loadingText`)}
                  </Box>
                )
              else if (isLoader && !response.hasNextPage)
                content = (
                  <Box w="100%" textAlign="center" color="#FFFFFF">
                    {t(`common.infinityLoader.noMoreText`)}
                  </Box>
                )
              else content = <CommentItem comment={comment} data-index={item.index} ref={virtualizer.measureElement} />

              return (
                <div
                  data-index={item.index}
                  key={item.key}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${item.size}px`,
                    transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`
                  }}>
                  {content}
                </div>
              )
            })}
          </VStack>
        </VStack>
      </VStack>

      <Modal modalId={createCommentModal.modalId} placement="top">
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)"
          gap="24px"
          alignItems="flex-start">
          <HStack gap="8px">
            <Divider color="#ADFF26" thickness="2px" orientation="vertical" h="15px" />
            <Box color="#FFFFFF" fontSize="16px" fontWeight={510}>
              {t(`routes.pair.comments.sender.heading`)}
            </Box>
          </HStack>
          <TextareaAutosize
            className={css({
              minWidth: 0,
              p: '12px 16px',
              color: '#FFFFFF',
              w: '100%',
              h: '100%',
              minH: '100px',
              rounded: '8px',
              bgColor: 'rgba(101, 102, 111, 0.5)',
              border: 'none',
              outline: 'none',
              flex: 1,
              fontSize: '14px',
              fontWeight: 400,
              caretColor: '#ADFF26',
              _placeholder: {
                color: 'rgba(255, 255, 255, 0.3)'
              }
            })}
            onClick={() => {
              if (!isConnectWallet) {
                connectWalletModal.open()
              }
            }}
            maxLength={150}
            placeholder={t(`routes.pair.comments.sender.placeholder`)}
            value={newComment}
            onChange={(e) => {
              if (!isConnectWallet) return
              setNewComment(e.target.value)
            }}
          />
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="1"
              h="48px"
              rounded="8px"
              bgColor="#575862"
              color="#FFFFFF"
              fontWeight={590}
              onClick={createCommentModal.close}>
              {t(`routes.pair.comments.sender.cancelLabel`)}
            </HStack>
            <HStack
              {...createDataset({
                'data-disabled': !newComment
              })}
              justifyContent="center"
              flex="1"
              h="48px"
              rounded="8px"
              bgColor="#426CFF"
              color="#FFFFFF"
              _disabled={{
                bgColor: '#575862',
                color: '#FFFFFF'
              }}
              fontWeight={590}
              onClick={() => createNewComment()}>
              {t(`routes.pair.comments.sender.confirmLabel`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>
    </>
  )
}
