export const copy = async (val: string): Promise<void> => {
  const legacyCopy = (): void => {
    const textArea = document.createElement('textArea') as HTMLTextAreaElement

    textArea.value = val
    textArea.style.width = '1px'
    textArea.style.position = 'fixed'
    textArea.style.left = '-999px'
    textArea.style.top = '10px'
    textArea.setAttribute('readonly', 'readonly')
    document.body.appendChild(textArea)

    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  if (navigator.clipboard && navigator.permissions) {
    try {
      await navigator.clipboard.writeText(val)
    } catch (error) {
      console.error(error)
      legacyCopy()
    }
  } else {
    legacyCopy()
  }
}
