// https://github.com/Innei/MetaMuse/blob/main/apps/console/src/providers/query-core.tsx

import { QueryCache, QueryClient } from '@tanstack/react-query'

import { type PersistedClient } from '@tanstack/react-query-persist-client'

import { TRPCClientError } from '@trpc/client'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { useAuthenticationStore } from '../authentication'

// declare module '@tanstack/query-core' {
//   export interface QueryMeta {
//     persist?: boolean
//   }
// }

interface ReactQueryStoreState {
  client: PersistedClient | undefined
  setClient: (client: PersistedClient) => void
  clear: () => void
}

export const useReactQueryStore = create(
  persist<ReactQueryStoreState, [], [], Pick<ReactQueryStoreState, 'client'>>(
    (set) => ({
      client: undefined,
      setClient: (client: PersistedClient): void => {
        console.debug(
          'persisting client',
          client.buster,
          client.timestamp,
          '\n',
          client.clientState.queries
            .map((q) => `[${Array.isArray(q.state.data) ? q.state.data.length : '-'}] ${q.queryHash}`)
            .join('\n')
        )
        set({ client })
      },
      clear: (): void => {
        console.debug('clear client')
        set({ client: undefined })
      }
    }),
    {
      name: 'react-query-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ client: state.client })
    }
  )
)

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error): void {
      // @ts-expect-error 这里可以访问到 data，要修一下这个 error 类型
      if (error?.data?.httpStatus === 401) {
        useAuthenticationStore.setState({
          authToken: null
        })
      }
    }
  }),
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      staleTime: 1000 * 60 * 7, // 7 minutes
      refetchOnMount: false,

      retry: (failureCount, error): boolean => {
        // https://github.com/pleisto/hopi/blob/main/apps/server/src/trpc/trpc.instance.ts#L11
        if (error instanceof TRPCClientError) {
          // disable retry for trpc errors.
          return false
        }
        return failureCount < 3
      }
    }
  }
})
