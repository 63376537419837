import { loggerLink } from '@trpc/react-query'
import { httpLink, splitLink, type HTTPHeaders } from '@trpc/client'
import { trpc } from './trpc'
import { APP_VERSION, SERVER_API_ENDPOINT } from '../../constant'
import { ensureValidJwtToken } from '../authentication'
import type { TrpcClientContext } from './interface'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TrpcHeaders = any

const buildCommonHeaders = (): HTTPHeaders => {
  return {
    'x-app-version': APP_VERSION,
    'x-app-source': 'webapp'
  } satisfies TrpcHeaders
}

export const tRpcClient = trpc.createClient({
  links: [
    loggerLink({
      enabled: (opts) => import.meta.env.DEV || (opts.direction === 'down' && opts.result instanceof Error)
    }),
    splitLink({
      condition(op) {
        return (op.context as TrpcClientContext).skipAuth === true
      },
      true: httpLink({
        url: `${SERVER_API_ENDPOINT}/trpc`,
        headers: buildCommonHeaders()
      }),
      false: httpLink({
        url: `${SERVER_API_ENDPOINT}/trpc`,
        headers: async () => {
          const token = await ensureValidJwtToken()

          return {
            ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
            ...buildCommonHeaders()
          }
        }
      })
    })
  ]
})
