import { hc } from 'hono/client'
import { type SoledgeAppType } from '@turbx/soledge'
import { APP_VERSION, SOLEDGE_API_ENDPOINT } from '../../constant'
// import { ensureValidJwtToken } from '../authentication'

const buildCommonHeaders = (): Record<string, string> => {
  return {
    'x-app-version': APP_VERSION,
    'x-app-source': 'webapp'
  }
}

export const soledgeClient = hc<SoledgeAppType>(SOLEDGE_API_ENDPOINT, {
  headers: async () => {
    // const token = await ensureValidJwtToken()

    return {
      // ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
      ...buildCommonHeaders()
    }
  }
})
