import { motion } from 'framer-motion'
import { useEffect, type FC, type PropsWithChildren } from 'react'
import { AnimatePresence } from '@turbx/deprecated-design-system'
import { useNotificationStore } from './notification.hook'
import { createPortal } from 'react-dom'
import { Float } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'

const dropIn = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    opacity: 0
  }
}

export interface NotificationProps {}

export const Notification: FC<PropsWithChildren<NotificationProps>> = () => {
  const isVisible = useNotificationStore((state) => state.isVisible)
  const message = useNotificationStore((state) => state.message)

  useEffect(() => {
    if (!isVisible) return

    const timer = setTimeout(() => {
      useNotificationStore.setState({
        isVisible: false,
        message: ''
      })
    }, 1500)

    return (): void => clearTimeout(timer)
  }, [isVisible])

  return createPortal(
    <AnimatePresence
      // Disable any initial animations on children that
      // are present when the component is first rendered
      initial={false}
      // Only render one component at a time.
      // The exiting component will finish its exit
      // animation before entering component is rendered
      mode="wait"
      onExitComplete={() => null}>
      {isVisible && (
        <Float placement="middle-center" pos="fixed" zIndex="9999">
          <motion.div
            onClick={(e) => e.stopPropagation()}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={css({
              bgColor: 'rgba(0, 0, 0, 0.8)',
              rounded: '8px',
              p: '10px 16px',
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: 400,
              textWrap: 'nowrap'
            })}>
            {message}
          </motion.div>
        </Float>
      )}
    </AnimatePresence>,
    document.body
  )
}
