import { Keypair, PublicKey, SystemProgram, TransactionInstruction } from '@solana/web3.js'
import bs58 from 'bs58'
import { ExecutorBaseService, type SendTransactionParams } from './base'
import type { ComputeBudgetConfig } from '@raydium-io/raydium-sdk'
import type { SolanaSwapExecutorType } from './interface'

// https://jito-labs.gitbook.io/mev/searcher-resources/json-rpc-api-reference/bundles/gettipaccounts
const jitoTipAccounts = [
  'Cw8CFyM9FkoMi7K7Crf6HNQqf4uEMzpKw6QNghXLvLkY',
  'DttWaMuVvTiduZRnguLF7jNxTgiMBZ1hyAumKUiL2KRL',
  '96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5',
  '3AVi9Tg9Uo68tJfuvoKvqKNWKkC5wPdSSdeBnizKZ6jT',
  'HFqU5x63VTqvQss8hp11i4wVV8bD44PvwucfZ2bU7gRe',
  'ADaUMid9yfUytqMBgopwjb2DTLSokTSzL1zt6iGPaS49',
  'ADuUkR4vqLUMWXxW9gh6D6L8pMSawimctcNZ5pGwDcEt',
  'DfXygSm4jCyNCybVYYK6DwvWqjKee8pbDmJGcLWNDXjh'
].map((pubkey) => new PublicKey(pubkey))

// https://jito-labs.gitbook.io/mev/searcher-resources/json-rpc-api-reference/url
const jitoEndpoints = [
  'https://mainnet.block-engine.jito.wtf/api/v1/bundles',
  'https://amsterdam.mainnet.block-engine.jito.wtf/api/v1/bundles',
  'https://frankfurt.mainnet.block-engine.jito.wtf/api/v1/bundles',
  'https://ny.mainnet.block-engine.jito.wtf/api/v1/bundles',
  'https://tokyo.mainnet.block-engine.jito.wtf/api/v1/bundles'
] as const

// https://github.com/hogyzen12/unruggable/blob/main/calypso/poseidon.js

export class JitoService extends ExecutorBaseService {
  override get name(): SolanaSwapExecutorType {
    return 'jito'
  }

  private tipAccount(): PublicKey {
    return jitoTipAccounts[Math.floor(Math.random() * jitoTipAccounts.length)]!
  }

  private getFeePercent(): number {
    const percent = this._options.cfg?.jito_compute_budget_fee_percent

    if (percent && percent >= 0 && percent < 1) return percent
    return 0
  }

  override computeBudgetConfig(fee: number): ComputeBudgetConfig | undefined {
    // https://solana.com/docs/core/fees#how-the-prioritization-fee-is-calculated
    // https://solana.com/docs/core/fees#request-the-minimum-compute-units
    const computeUnitLimit = 300_000
    // https://solscan.io/tx/5Aokx1qS6nobHu6JGtkTXtEESiFw7et1Sng2Afa49ckW9t99DEmarbmmxNyRg8uNaeN7DnuCPDUP5TxZUa3qgB3F
    // 0.0015 * 1000_000_000 / 300_000 * 1_000_000 === 5_000_000

    const percent = this.getFeePercent()
    if (percent === 0) return undefined

    const maxLamports = Math.round(fee / computeUnitLimit) * 1_000_000 * percent

    return { units: computeUnitLimit, microLamports: maxLamports }
  }

  override buildExtraFeeTransactionInstruction(payer: Keypair, feeLamports: number): TransactionInstruction[] {
    const lamports = Math.round(feeLamports * (1 - this.getFeePercent()))
    return [SystemProgram.transfer({ fromPubkey: payer.publicKey, toPubkey: this.tipAccount(), lamports })]
  }

  // https://github.com/warp-id/solana-trading-bot/blob/master/transactions/jito-rpc-transaction-executor.ts#L107
  override async sendTransaction({ tx }: SendTransactionParams): Promise<object> {
    const serializedTransaction = bs58.encode(tx.serialize())
    const serializedTransactions = [serializedTransaction]

    // https://jito-labs.gitbook.io/mev/searcher-resources/json-rpc-api-reference/bundles/sendbundle
    const requests = jitoEndpoints.map((url) =>
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ jsonrpc: '2.0', id: 1, method: 'sendBundle', params: [serializedTransactions] })
      }).then((response) => response.json())
    )

    const results = await Promise.all(requests.map((p) => p.catch((e) => e)))
    const successfulResults = results.filter((result) => !(result instanceof Error))

    if (successfulResults.length > 0) {
      const firstResult = successfulResults[0]! as { result: string }
      const bundleId = firstResult.result
      this.logger.info({ bundleId, size: successfulResults.length, firstResult }, 'jito send transaction successful')

      return { type: this.name, jitoBundleId: bundleId }
    }

    this.logger.error({ results }, 'jito send transaction failed')

    throw new Error('Send transaction failed')
  }
}
