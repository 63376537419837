/* eslint-disable */

import { parseResolution } from './trading-view-helpers'
import { soledgeClient } from '@/stores'

declare module './charting-library.d.ts' {
  interface LibrarySymbolInfo {
    address: string
  }
}

// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '1W', '1M'] as any,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: 'Bitfinex', name: 'Bitfinex', desc: 'Bitfinex' },
    { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange' }
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: 'crypto', value: 'crypto' }]
} satisfies TradingView.DatafeedConfiguration

// Use it to keep a record of the most recent bar on the chart
const lastBarsCache = new Map()

let timeoutId: NodeJS.Timeout | null = null
let lastUpdateTime: number = 0

let subscriptionPayload: {
  symbolInfo: TradingView.LibrarySymbolInfo
  resolution: TradingView.ResolutionString
  onRealtimeCallback: TradingView.SubscribeBarsCallback
} | null = null
let isRequestInProgress: boolean = false

function roundToNearestMultipleOf5(timestamp: number): number {
  return Math.floor(timestamp / 5) * 5
}

function scheduleNextFetch() {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  timeoutId = setTimeout(fetchAndUpdateData, 5000)
}

async function fetchAndUpdateData() {
  if (!subscriptionPayload) return

  if (isRequestInProgress) {
    scheduleNextFetch()
    return
  }

  isRequestInProgress = true

  const now = Math.floor(Date.now() / 1000)

  const time_from = lastUpdateTime || roundToNearestMultipleOf5(now - 300) // Start from 5 minutes ago if it's the first fetch
  const time_to = roundToNearestMultipleOf5(now)

  console.log('fetching', { now, lastUpdateTime, time_from, time_to })

  const params = {
    address: subscriptionPayload.symbolInfo.address,
    addressType: 'token',
    type: parseResolution(subscriptionPayload.resolution),
    time_from: time_from,
    time_to: time_to
  } as const

  try {
    const bars = await getOhlcvBars(params)
    if (bars.length > 0) {
      bars.forEach((bar) => subscriptionPayload!.onRealtimeCallback(bar))
    }
    lastUpdateTime = time_to
  } catch (error) {
    console.error('Error fetching data:', error, params)
  } finally {
    isRequestInProgress = false
    scheduleNextFetch()
  }
}

const getOhlcvBars = async ({
  time_from,
  time_to,
  ...params
}: {
  address: string
  addressType: 'token'
  type: string
  time_from: number
  time_to: number
}): Promise<TradingView.Bar[]> => {
  const response = await soledgeClient.prices.ohlcv.$get({
    query: { ...params, time_from: time_from.toString(), time_to: time_to.toString() }
  })

  if (!response.ok) throw new Error(`${response.status} ${response.statusText}`)

  const data = await response.json()
  if (!data.success) throw new Error(data.message)

  const bars = data.data.items.reduce((prev: any, cur: any) => {
    if (cur.unixTime >= time_from && cur.unixTime < time_to) {
      return [...prev, { time: cur.unixTime * 1000, low: cur.l, high: cur.h, open: cur.o, close: cur.c }]
    }
    return prev
  }, [])

  console.log(`[getBars]: returned ${bars.length} bar(s)`)

  return bars
}

// https://github.com/472647301/tradingview-web-socket/blob/master/tv-react/src/datafeed/index.ts#L20
// https://www.tradingview.com/charting-library-docs/latest/tutorials/implement_datafeed_tutorial/Datafeed-Implementation
// https://github.com/birdeye-so/tradingview-example-js-api/blob/main/src/datafeed.js#L75-L130

const datafeeds: TradingView.IExternalDatafeed & TradingView.IDatafeedChartApi = {
  onReady: (callback) => {
    console.log('[onReady]: Method call')
    setTimeout(() => callback(configurationData))
  },

  searchSymbols: async (_userInput, _exchange, _symbolType, onResultReadyCallback) => {
    console.log('[searchSymbols]: Method call')
    onResultReadyCallback([])
  },

  resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, _extension) => {
    const symbol: TradingView.LibrarySymbolInfo = {
      ticker: 'ticker',
      name: 'name',
      address: '6e6rViDzavLRv56nvZye5UofrKDg36mf6dTQrMCoPTW9',
      description: 'description',
      type: 'type',
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: 'exchange',
      minmov: 1,
      pricescale: 100,
      has_intraday: false,
      // visible_plots_set: 'ohlc',
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: 'streaming',
      full_name: '',
      listed_exchange: '',
      format: 'price'
    }
    if (!symbol) {
      console.log('[resolveSymbol]: Cannot resolve symbol', symbolName)
      onResolveErrorCallback('Cannot resolve symbol')
      return
    }
    // Symbol information object
    console.log('[resolveSymbol]: Symbol resolved', symbolName)
    onSymbolResolvedCallback(symbol)
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams
    console.log('[getBars]: Method call', symbolInfo, resolution, from, to)
    const params = {
      address: symbolInfo.address,
      addressType: 'token',
      type: parseResolution(resolution),
      time_from: from,
      time_to: to
    } as const

    try {
      const bars = await getOhlcvBars(params)

      if (bars.length === 0) {
        onHistoryCallback([], { noData: true })
        return
      }

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.address, { ...bars[bars.length - 1] })
      }

      console.log(`[getBars]: returned ${bars.length} bar(s)`)
      onHistoryCallback(bars, { noData: false })
    } catch (error) {
      console.log('[getBars]: Get error', error, params)
      onErrorCallback((error as Error).message)
    }
  },

  // https://github.com/birdeye-so/tradingview-example-js-api/blob/main/src/streaming.js
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, _onResetCacheNeededCallback) => {
    subscriptionPayload = { symbolInfo, resolution, onRealtimeCallback }
    fetchAndUpdateData() // Fetch data immediately
    console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID, resolution, symbolInfo)
  },

  unsubscribeBars: (subscriberUID) => {
    if (timeoutId) {
      clearInterval(timeoutId)
      timeoutId = null
    }
    subscriptionPayload = null
    lastUpdateTime = 0

    console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID)
  }
}

export default datafeeds
