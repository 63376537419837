import { useState, type FC } from 'react'
import { TokenHeat } from './token-heat.component'
import * as styles from './token.css'
import avatarPlaceholder from './assets/token/token-avatar-placeholder.svg'
import darkAvatarPlaceholder from './assets/token/token-avatar-placeholder-dark.svg'
import { cx } from '@/styled-system/css'

export const TokenAvatar: FC<{
  heat: number
  avatar: string
  dark?: boolean
}> = ({ avatar, heat, dark }) => {
  const [avatarSrc, setAvatarSrc] = useState(avatar)
  const [visible, setVisible] = useState(false)

  return (
    <div className={cx(styles.tokenAvatarContainer, dark && heat > 0.85 && styles.darkTokenHeatEffect)}>
      <TokenHeat className={styles.tokenAvatarHeat} heat={heat} />
      <img
        style={{
          opacity: visible ? 1 : 0
        }}
        className={styles.tokenAvatar}
        src={avatarSrc}
        onLoad={() => setVisible(true)}
        onError={() => {
          setAvatarSrc(dark ? darkAvatarPlaceholder : avatarPlaceholder)
          setVisible(true)
        }}
      />
      {/* <div className={styles.tokenAvatar} style={{ backgroundImage: `url('${avatar}')` }} /> */}
    </div>
  )
}
