export const AbortException = 'Aborted'
/**
 * Sleeps for the specified amount of time.
 * @param milliseconds
 * @returns
 */
export const sleep = (milliseconds: number): { promise: Promise<void>; cancel: () => void } => {
  let timeout: ReturnType<typeof setTimeout>
  const controller = new AbortController()
  return {
    promise: new Promise((resolve, reject) => {
      controller.signal.addEventListener('abort', () => {
        reject(AbortException)
      })
      timeout = setTimeout(resolve, milliseconds)
    }),
    cancel: () => {
      clearTimeout(timeout)
      controller.abort()
    }
  }
}
