import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources, locales, defaultLocale } from '@/locales'

export const initI18n = (): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng: defaultLocale.value,
    fallbackLng: defaultLocale.value,
    supportedLngs: locales.map((l) => l.value),

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
}
