import type { FC } from 'react'

// 计算圆弧上点的坐标
const calcCoordinateOnCircle = (
  centerX: number,
  centerY: number,
  radiusX: number,
  radiusY: number,
  angle: number
): {
  x: number
  y: number
} => {
  const theta = (angle * Math.PI) / 180

  const x = centerX + radiusX * Math.cos(Math.PI / 2 + theta)
  const y = centerY - radiusY * Math.sin(Math.PI / 2 + theta)

  return {
    x,
    y
  }
}

export interface TokenHeatProps {
  size?: number
  strokeWidth?: number
  heat: number
  className?: string
}

export const TokenHeat: FC<TokenHeatProps> = ({ size = 40, strokeWidth = 2, heat, className }) => {
  const width = size
  const height = size
  const radiusX = size / 2 - strokeWidth
  const radiusY = radiusX

  // 从 bottom center 开始画
  const centerX = width / 2
  const centerY = height / 2
  const { x: startX, y: startY } = calcCoordinateOnCircle(centerX, centerY, radiusX, radiusY, 180)
  // 由于是对称图形，所以只需按半圆计算
  const angle1 = 180 * (1 - heat)
  const { x: rightEndX, y: rightEndY } = calcCoordinateOnCircle(centerX, centerY, radiusX, radiusY, angle1)
  // 对称计算另一半
  const angle2 = 360 - angle1
  const { x: leftEndX, y: leftEndY } = calcCoordinateOnCircle(centerX, centerY, radiusX, radiusY, angle2)

  /**
   * SVG 弧线命令
   * A(rx, ry, xr, laf, sf, x, y) - 绘制弧线
   * rx - (radius-x)：弧线所在椭圆的 x 半轴长
   * ry - (radius-y)：弧线所在椭圆的 y 半轴长
   * xr - (xAxis-rotation)：弧线所在椭圆的长轴角度
   * laf - (large-arc-flag)：是否选择弧长较长的那一段弧
   * sf - (sweep-flag)：是否选择逆时针方向的那一段弧
   * x, y：弧的终点位置
   */

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0" y1="1" x2="0" y2="0">
          <stop offset="0%" stopColor="#F4EB00" />
          <stop offset="100%" stopColor="#FF0000" />
        </linearGradient>
      </defs>
      <circle fill="none" cx={centerX} cy={centerY} r={radiusX} stroke="#00000012" strokeWidth={strokeWidth} />
      <path
        strokeLinecap="round"
        d={`M ${startX} ${startY} A ${radiusX} ${radiusY} 0 0 1 ${rightEndX} ${rightEndY}`}
        fill="none"
        stroke="url('#gradient')"
        strokeWidth={strokeWidth}
      />
      <path
        strokeLinecap="round"
        d={`M ${startX} ${startY} A ${radiusX} ${radiusY} 0 0 0 ${leftEndX} ${leftEndY}`}
        fill="none"
        stroke="url('#gradient')"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
