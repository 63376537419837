export const locales = [
  {
    label: 'English',
    value: 'en-US'
  }
]

export const defaultLocale = {
  label: 'English',
  value: 'en-US'
}

export const resources = {
  'en-US': {
    translation: {
      common: {
        authorization: {
          twitter: {
            failed: {
              message: 'Twitter authentication failed'
            }
          },
          wallet: {
            failed: {
              message: 'Authorization failed. Please try again'
            }
          }
        },
        copyToast: 'copied',
        infinityLoader: {
          loadingText: 'Loading...',
          noContentText: 'Nothing yet...',
          noMoreText: 'It is the end.'
        },
        searchMode: {
          searchingText: 'Searching...',
          notFoundText: 'Not found.'
        },
        connectWalletModal: {
          heading: 'Connect wallet',
          message: 'Generate / Import a wallet to continue',
          cancelButton: {
            label: 'Later'
          },
          confirmButton: {
            label: 'Connect'
          }
        }
      },
      routes: {
        exception: {
          homeButton: {
            label: 'Home'
          },
          development: {
            // message: 'The server is only open to invited users.'
            message: 'TurbX Beta V1.0.1 testing phase has ended.'
          },
          notFound: {
            message: 'Page not found...'
          },
          error: {
            message: 'Something was error...'
          }
        },
        profile: {
          title: 'My Account',
          connectWalletButton: {
            label: 'Connect wallet'
          },
          point: {
            label: 'Point'
          },
          contactUs: {
            label: 'Contact us',
            modal: {
              heading: 'Contact us'
            }
          }
        },
        signIn: {
          title: 'Sign in',
          heading: 'Welcome to TurbX',
          description: 'Start sharing your crypto journey',
          walletSignInButton: {
            label: 'Connect wallet'
          },
          telegramSignInButton: {
            label: 'Sign in with Telegram'
          },
          agreement: {
            label: 'Clicking the button will be considered as agreeing to the <agreement />'
          },
          agreementLink: {
            label: 'user agreement'
          },
          authorize: {
            heading: 'Connect the wallet',
            description: 'Start sharing your crypto journey',
            connectButton: {
              label: 'Connect wallet'
            },
            successHint: {
              label: 'Please return to <appName /> to complete the connection'
            },
            successHintAppName: {
              label: 'Turbx App'
            }
          }
        },
        index: {
          title: 'Home',
          heading: 'TurbX',
          slogan: 'Start your crypto journey today',
          searchInput: {
            placeholder: 'Search Tokens on Solana...'
          },
          searching: 'Searching...'
        },
        pair: {
          '404': {
            message: 'Token not found, please check and try again',
            button: {
              label: 'Back'
            }
          },
          loading: {
            message: 'Loading...'
          },
          comments: {
            heading: 'Comment',
            orderBy: {
              latest: {
                label: 'Latest'
              },
              popular: {
                label: 'Popular'
              }
            },
            sender: {
              button: {
                label: 'Comment'
              },
              heading: 'Leave a comment',
              placeholder: 'Leave a comment...',
              confirmLabel: 'Send',
              cancelLabel: 'Cancel'
            }
          },
          modal: {
            sellConfirm: {
              balance: {
                label: 'Balance'
              },
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Sell'
              }
            },
            sellCustom: {
              balance: {
                label: 'Balance'
              },
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Sell'
              }
            },
            buyConfirm: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Buy'
              }
            },
            buyCustom: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Buy'
              }
            },
            slippage: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm'
              }
            },
            priorityFee: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm'
              }
            }
          },
          detail: {
            marketCap: {
              label: 'MarketCap'
            },
            balance: {
              label: 'Balance'
            },
            renounced: {
              label: 'Renounced'
            },
            burned: {
              label: 'Burned'
            }
          },
          transaction: {
            sent: {
              message: 'Transaction sent'
            },
            success: {
              message: 'Transfer success'
            },
            error: {
              default: `Solana chain congestion`,
              insufficientBalance: 'Insufficient balance'
            },
            failed: {
              message: 'Transaction failed'
            },
            settings: {
              slippage: {
                label: 'Slippage',
                auto: {
                  label: 'Auto'
                }
              },
              priorityFee: {
                label: 'Priority fee',
                custom: {
                  label: 'Custom'
                }
              }
            },
            buy: {
              label: 'Buy',
              custom: {
                label: 'Custom'
              }
            },
            sell: {
              label: 'Sell',
              custom: {
                label: 'Custom'
              }
            },
            tip: `we'll charge 1% from every transaction`
          }
        },
        tokenList: {
          title: 'Tokens',
          tabs: {
            popular: {
              label: 'Popular'
            },
            owned: {
              label: 'My tokens'
            },
            favorite: {
              label: 'Favorites'
            }
          }
        },
        wallet: {
          title: 'My Wallet',
          copyToast: 'copied',
          selectType: {
            heading: 'Generate a new wallet or import your private key to start',
            generateButton: {
              label: 'Generate'
            },
            privateKeyButton: {
              label: 'Import your private key'
            },
            privateKeyModal: {
              title: 'Your private key',
              cancelButton: {
                label: 'Cancel'
              },
              confirmButton: {
                label: 'Import'
              }
            }
          },
          import: {
            heading: 'This is your ONLY chance to copy the private key.',
            description: `Please save this private key somewhere safe and accessible. For security reasons, you won't be able to view it again through your TurbX account. If you lose this private key, you'll need to generate a new one.`,
            copyButton: {
              label: 'Copy'
            },
            nextButton: {
              label: 'Next'
            },
            hint: 'Copy to move forward'
          },
          myWallet: {
            heading: 'My Wallet',
            description: 'This is the text about wallets, Please save this secret key somewhere safe and accessible.',
            balance: {
              label: 'Balance'
            },
            address: {
              label: 'Address',
              copyButton: {
                label: 'Copy'
              }
            },
            disconnectButton: {
              label: 'Disconnect'
            },
            disconnectModal: {
              title: 'Sure to disconnect the wallet?',
              message: 'Make sure all your assets were secured before you disconnect.',
              cancelButton: {
                label: 'Later'
              },
              confirmButton: {
                label: 'Confirm'
              }
            }
          }
        }
      }
    }
  }
}
