import { forwardRef, type SVGProps } from 'react'
import type { IconSymbol } from '../iconType.gen'

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconSymbol
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(({ name, color = 'currentColor', ...props }, ref) => {
  const symbolId = `#${name}`

  return (
    <svg ref={ref} {...props} aria-hidden="true">
      <use href={symbolId} fill={color} />
    </svg>
  )
})
