import { useEffect } from 'react'
import { create } from 'zustand'

export type PlatformType =
  | 'ios'
  | 'android'
  | 'telegram-web'
  | 'mobile-web'
  | 'macos-app'
  | 'windows-app'
  | 'desktop-web'
  | 'ipad'

export interface PlatformStoreState {
  current: PlatformType
  dimensions: {
    screenHeight: number
    screenWidth: number
  }
}

export const usePlatformStore = create<PlatformStoreState>(() => ({
  current: window.Telegram?.WebApp?.initData ? 'telegram-web' : 'mobile-web',
  dimensions: {
    screenHeight: window.innerHeight,
    screenWidth: window.innerWidth
  }
}))

export function useWindowResize(): void {
  const currentPlatform = usePlatformStore((state) => state.current)

  useEffect(() => {
    if (currentPlatform === 'telegram-web' || currentPlatform === 'desktop-web' || currentPlatform === 'mobile-web') {
      const listener = (): void =>
        usePlatformStore.setState({
          dimensions: {
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth
          }
        })
      window.addEventListener('resize', listener)
      return (): void => window.removeEventListener('resize', listener)
    }
  }, [currentPlatform])
}
