import * as web3 from '@solana/web3.js'
import { type RpcAccount, type Umi } from '@metaplex-foundation/umi'
import { mplTokenMetadata, type DigitalAssetWithToken, type Metadata } from '@metaplex-foundation/mpl-token-metadata'
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import {
  MOONSHOT_PROGRAM,
  PUMPFUN_PROGRAM,
  type ExtendedLiquidityStateV4,
  type ExtendedPoolInfoV4,
  type ExtendedRawMint,
  type NFTMintPublicKeyType,
  type PoolBaseType,
  type PoolNormalizedType,
  type SolanaClientLoggers,
  type TokenAccountType,
  type TokenBaseType,
  type Web3AddressesAddress,
  type Web3PairsPair,
  type Web3RaydiumPair,
  type Web3TokensMint
} from './interface'
import { fetchMultipleSingleTokenAccounts, fetchTokenAccount } from './nft'
import { MintLayout, TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { formatAmmKeysById, getMintsByPair, getProgramAccounts } from './pair'
import { SOLANA_ADDRESS, TOKEN_METADATA_PROGRAM_ID } from './constant'
import { isValidSolanaAddress } from './keypair'
import { mintState } from './token'
import { deserializeMetadata } from '@metaplex-foundation/mpl-token-metadata'
import {
  Liquidity,
  SPL_ACCOUNT_LAYOUT,
  type ApiTokenInfo,
  type LiquidityPoolInfo,
  type LiquidityPoolKeys
} from '@raydium-io/raydium-sdk'
import { sleep } from './utils'
import {
  AMM_V4,
  Api,
  CLMM_PROGRAM_ID,
  CpmmPoolInfoLayout,
  CREATE_CPMM_POOL_PROGRAM,
  liquidityStateV4Layout,
  PoolInfoLayout,
  publicKey,
  Raydium,
  SOL_INFO,
  struct,
  type BasicPoolInfo,
  type ReturnTypeGetAllRoute,
  type TokenInfo
} from '@raydium-io/raydium-sdk-v2'

type OnChainResult = PoolNormalizedType
export type SearchOnChainResult =
  | { type: 'invalid'; result: null }
  | { type: 'mint'; result: OnChainResult }
  | { type: 'mint'; result: null; mint: Web3TokensMint }
  | { type: 'pair'; result: OnChainResult }
  | { type: 'error'; result: null; reason: string }

type MintAccountInfoType = {
  decimals: number
  program_id: string
  source: 'moonshot' | 'pumpfun' | null
}

export class SolanaBaseClient {
  public readonly connection: web3.Connection
  private readonly umi: Umi
  private readonly earlyAccessMintPublicKey: NFTMintPublicKeyType
  private readonly logger: SolanaClientLoggers
  public readonly raydium: Raydium

  constructor(options: {
    logger: SolanaClientLoggers
    connection: web3.Connection
    earlyAccessMintPublicKey: NFTMintPublicKeyType
  }) {
    this.logger = options.logger
    this.connection = options.connection
    this.earlyAccessMintPublicKey = options.earlyAccessMintPublicKey
    this.umi = createUmi(this.connection.rpcEndpoint)
    this.umi.use(mplTokenMetadata())

    const apiRequestTimeout = 10 * 1000

    const api = new Api({
      cluster: 'mainnet',
      timeout: apiRequestTimeout,
      urlConfigs: undefined,
      logCount: undefined,
      logRequests: true
    })

    // https://github.com/raydium-io/raydium-sdk-V2-demo/blob/master/src/config.ts.template#L16-L26
    // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/raydium.ts#L154
    this.raydium = new Raydium({
      cluster: 'mainnet',
      connection: this.connection,
      disableFeatureCheck: true,
      disableLoadToken: true,
      blockhashCommitment: 'confirmed',
      apiRequestInterval: 5 * 60 * 1000,
      apiRequestTimeout,
      api
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  }

  // https://github.com/raydium-io/raydium-sdk#tokens-list
  async inactiveRaydiumTokens(): Promise<Omit<TokenBaseType, 'program_id'>[]> {
    const resp = await fetch('https://api.raydium.io/v2/sdk/token/raydium.mainnet.json')
    const json = (await resp.json()) as ApiTokenInfo

    const tokens = [
      ...json.official,
      ...json.unOfficial
      // ...json.unNamed.map(token =>
      //   this.renderRaydiumToken({ ...token, symbol: token.mint, name: token.mint, icon: '' }, 'unNamed')
      // )
    ]

    return tokens.map((t) => ({
      mint: t.mint as Web3TokensMint,
      name: t.name,
      symbol: t.symbol,
      decimals: t.decimals,
      logo: t.icon
    }))
  }

  private renderRaydiumPools(pools: BasicPoolInfo[]): PoolBaseType[] {
    return pools.map((pool) => ({
      pair: pool.id.toBase58() as Web3RaydiumPair,
      mint_a: pool.mintA.toBase58() as Web3TokensMint,
      mint_b: pool.mintB.toBase58() as Web3TokensMint,
      version: pool.version
    }))
  }

  // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/tradeV2/trade.ts#L618
  // https://github.com/raydium-io/raydium-sdk-V2-demo/blob/master/src/trade/routeSwap.ts#L54
  async raydiumRouteDetails(inputMint: Web3TokensMint, outputMint: Web3TokensMint, routes: ReturnTypeGetAllRoute) {
    return await this.raydium.tradeV2.fetchSwapRoutesData({ routes, inputMint, outputMint })
  }

  // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/tradeV2/trade.ts#L327
  // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/tradeV2/trade.ts#L383
  async raydiumRoutes(inputMint: Web3TokensMint, outputMint: Web3TokensMint): Promise<ReturnTypeGetAllRoute> {
    const [ammPoolsData, ammReversedPoolsData, clmmPoolsData, clmmReversedPoolsData, cpmmPools, cpmmReversedPools] =
      await Promise.all([
        this.connection.getProgramAccounts(AMM_V4, {
          filters: [
            { memcmp: { offset: liquidityStateV4Layout.offsetOf('baseMint'), bytes: inputMint } },
            { memcmp: { offset: liquidityStateV4Layout.offsetOf('quoteMint'), bytes: outputMint } }
          ],
          dataSlice: { offset: liquidityStateV4Layout.offsetOf('baseMint'), length: 64 }
        }),
        this.connection.getProgramAccounts(AMM_V4, {
          filters: [
            { memcmp: { offset: liquidityStateV4Layout.offsetOf('baseMint'), bytes: outputMint } },
            { memcmp: { offset: liquidityStateV4Layout.offsetOf('quoteMint'), bytes: inputMint } }
          ],
          dataSlice: { offset: liquidityStateV4Layout.offsetOf('baseMint'), length: 64 }
        }),
        this.connection.getProgramAccounts(CLMM_PROGRAM_ID, {
          filters: [
            { dataSize: PoolInfoLayout.span },
            { memcmp: { offset: PoolInfoLayout.offsetOf('mintA'), bytes: inputMint } },
            { memcmp: { offset: PoolInfoLayout.offsetOf('mintB'), bytes: outputMint } }
          ],
          dataSlice: { offset: PoolInfoLayout.offsetOf('mintA'), length: 64 }
        }),
        this.connection.getProgramAccounts(CLMM_PROGRAM_ID, {
          filters: [
            { dataSize: PoolInfoLayout.span },
            { memcmp: { offset: PoolInfoLayout.offsetOf('mintA'), bytes: outputMint } },
            { memcmp: { offset: PoolInfoLayout.offsetOf('mintB'), bytes: inputMint } }
          ],
          dataSlice: { offset: PoolInfoLayout.offsetOf('mintA'), length: 64 }
        }),
        this.connection.getProgramAccounts(CREATE_CPMM_POOL_PROGRAM, {
          filters: [
            { memcmp: { offset: CpmmPoolInfoLayout.offsetOf('mintA'), bytes: inputMint } },
            { memcmp: { offset: CpmmPoolInfoLayout.offsetOf('mintB'), bytes: outputMint } }
          ],
          dataSlice: { offset: CpmmPoolInfoLayout.offsetOf('mintA'), length: 64 }
        }),
        this.connection.getProgramAccounts(CREATE_CPMM_POOL_PROGRAM, {
          filters: [
            { memcmp: { offset: CpmmPoolInfoLayout.offsetOf('mintA'), bytes: outputMint } },
            { memcmp: { offset: CpmmPoolInfoLayout.offsetOf('mintB'), bytes: inputMint } }
          ],
          dataSlice: { offset: CpmmPoolInfoLayout.offsetOf('mintA'), length: 64 }
        })
      ])

    const layoutAmm = struct([publicKey('baseMint'), publicKey('quoteMint')])
    const ammData = [...ammPoolsData, ...ammReversedPoolsData].map((data) => ({
      id: data.pubkey,
      version: 4,
      mintA: layoutAmm.decode(data.account.data).baseMint,
      mintB: layoutAmm.decode(data.account.data).quoteMint
    }))

    const layout = struct([publicKey('mintA'), publicKey('mintB')])

    const clmmData = [...clmmPoolsData, ...clmmReversedPoolsData].map((data) => {
      const clmm = layout.decode(data.account.data)
      return {
        id: data.pubkey,
        version: 6,
        mintA: clmm.mintA,
        mintB: clmm.mintB
      }
    })

    const cpmmData = [...cpmmPools, ...cpmmReversedPools].map((data) => {
      const cpmm = layout.decode(data.account.data)
      return {
        id: data.pubkey,
        version: 7,
        mintA: cpmm.mintA,
        mintB: cpmm.mintB
      }
    })

    this.logger.info('fetch pools data', { clmmData, ammData, cpmmData })

    return this.raydium.tradeV2.getAllRoute({
      inputMint: new web3.PublicKey(inputMint),
      outputMint: new web3.PublicKey(outputMint),
      clmmPools: clmmData,
      ammPools: ammData,
      cpmmPools: cpmmData
    })
  }

  async raydiumPools(): Promise<{
    ammPools: PoolBaseType[]
    clmmPools: PoolBaseType[]
    cpmmPools: PoolBaseType[]
  }> {
    // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/tradeV2/trade.ts#L355
    const {
      ammPools: ammData,
      clmmPools: clmmData,
      cpmmPools: cpmmData
    } = await this.raydium.tradeV2.fetchRoutePoolBasicInfo()

    return {
      ammPools: this.renderRaydiumPools(ammData),
      clmmPools: this.renderRaydiumPools(clmmData),
      cpmmPools: this.renderRaydiumPools(cpmmData)
    }
  }

  // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/token/token.ts#L27
  async activeRaydiumTokens(): Promise<TokenBaseType[]> {
    const [{ mintList, blacklist }, jup] = await Promise.all([
      this.raydium.api.getTokenList(),
      this.raydium.api.getJupTokenList()
    ])

    const _tokenMap = new Map()
    const _blackTokenMap = new Map()
    const _mintGroup = { official: new Set(), jup: new Set(), extra: new Set() }

    const _extraTokenList: TokenInfo[] = []

    _tokenMap.set(SOL_INFO.address, SOL_INFO)
    _mintGroup.official.add(SOL_INFO.address)

    blacklist.forEach((token) => {
      _blackTokenMap.set(token.address, { ...token, priority: -1 })
    })

    mintList.forEach((token) => {
      if (_blackTokenMap.has(token.address)) return
      _tokenMap.set(token.address, {
        ...token,
        type: 'raydium',
        priority: 2,
        programId:
          token.programId ??
          (token.tags.includes('token-2022') ? TOKEN_2022_PROGRAM_ID.toBase58() : TOKEN_PROGRAM_ID.toBase58())
      })
      _mintGroup.official.add(token.address)
    })

    jup.forEach((token) => {
      if (_blackTokenMap.has(token.address) || _tokenMap.has(token.address)) return
      _tokenMap.set(token.address, {
        ...token,
        type: 'jupiter',
        priority: 1,
        programId:
          token.programId ??
          (token.tags.includes('token-2022') ? TOKEN_2022_PROGRAM_ID.toBase58() : TOKEN_PROGRAM_ID.toBase58())
      })
      _mintGroup.jup.add(token.address)
    })

    _extraTokenList.forEach((token) => {
      if (_blackTokenMap.has(token.address) || _tokenMap.has(token.address)) return
      _tokenMap.set(token.address, {
        ...token,
        type: 'extra',
        priority: 1,
        programId:
          token.programId || token.tags.includes('token-2022')
            ? TOKEN_2022_PROGRAM_ID.toBase58()
            : TOKEN_PROGRAM_ID.toBase58()
      })
      _mintGroup.extra.add(token.address)
    })

    const tokenLists: TokenInfo[] = Array.from(_tokenMap).map((data) => data[1])
    return tokenLists.map((t) => ({
      mint: t.address as Web3TokensMint,
      name: t.name,
      symbol: t.symbol,
      decimals: t.decimals,
      logo: t.logoURI,
      program_id: t.programId
    }))
  }

  async confirmTransaction(transaction_id: string) {
    // https://github.com/warp-id/solana-trading-bot/blob/master/transactions/default-transaction-executor.ts#L38
    const recentBlockhashForSwap = await this.connection.getLatestBlockhash()

    return await this.connection.confirmTransaction(
      {
        signature: transaction_id,
        blockhash: recentBlockhashForSwap.blockhash,
        lastValidBlockHeight: recentBlockhashForSwap.lastValidBlockHeight
      },
      this.connection.commitment
    )
  }

  async fetchEarlyAccessTokenAccount(owner: Web3AddressesAddress): Promise<DigitalAssetWithToken | null> {
    const asset = await fetchTokenAccount(this.umi, this.earlyAccessMintPublicKey, owner)
    this.logger.info({ asset, owner, mint: this.earlyAccessMintPublicKey }, 'fetch early access token account')
    return asset
  }

  async fetchMultipleEarlyAccessTokenAccounts(owners: Web3AddressesAddress[]): Promise<DigitalAssetWithToken[]> {
    const assets = await fetchMultipleSingleTokenAccounts(this.umi, this.earlyAccessMintPublicKey, owners)
    this.logger.info(
      { assets, owners, mint: this.earlyAccessMintPublicKey },
      'fetch mutiple early access token accounts'
    )
    return assets
  }

  async getBalance(owner: Web3AddressesAddress): Promise<number> {
    const balance = await this.connection.getBalance(new web3.PublicKey(owner))
    this.logger.info({ balance, owner }, 'get balance')
    return balance
  }

  // https://solana.stackexchange.com/a/7348
  async getMultipleBalances(addresses: [Web3AddressesAddress, ...Web3AddressesAddress[]]): Promise<number[]> {
    const accountsInfo = await this.connection.getMultipleAccountsInfo(addresses.map((a) => new web3.PublicKey(a)))

    const result = accountsInfo.map((a) => {
      if (a === null) return 0
      return a.lamports / web3.LAMPORTS_PER_SOL
    })
    this.logger.info({ addresses, accountsInfo, result }, 'get multiple balance')

    return result
  }

  // https://github.com/mirror520/first-web3/blob/main/src/app/solana.service.ts#L98
  // https://www.quicknode.com/guides/solana-development/spl-tokens/how-to-get-all-tokens-held-by-a-wallet-in-solana
  private renderTokenAccount(account: {
    pubkey: web3.PublicKey
    account: web3.AccountInfo<web3.ParsedAccountData>
  }): TokenAccountType {
    // https://github.com/solana-labs/solana-web3.js/blob/master/packages/rpc-parsed-types/src/token-accounts.ts#L8-L19
    const info = account.account.data.parsed.info
    const tokenAmount = info.tokenAmount as web3.TokenAmount
    return {
      pubkey: account.pubkey.toBase58() as Web3AddressesAddress,
      balance: tokenAmount.uiAmountString!,
      mint: info.mint as Web3TokensMint
    }
  }

  async getTokenAccounts(owner: Web3AddressesAddress): Promise<TokenAccountType[]> {
    const owner1 = new web3.PublicKey(owner)
    const [atsOld, atsNew] = await Promise.all([
      await this.connection.getParsedTokenAccountsByOwner(owner1, { programId: TOKEN_PROGRAM_ID }),
      await this.connection.getParsedTokenAccountsByOwner(owner1, { programId: TOKEN_2022_PROGRAM_ID })
    ])
    const result0 = [...atsOld.value, ...atsNew.value]

    const result1 = result0.map((r) => this.renderTokenAccount(r))

    this.logger.info({ result0, result1, owner, atsOld, atsNew }, 'get token accounts')

    return result1
  }

  async getOwnerTokenAccounts(pk: string) {
    const walletTokenAccount = await this.connection.getTokenAccountsByOwner(new web3.PublicKey(pk), {
      programId: TOKEN_PROGRAM_ID
    })

    return walletTokenAccount.value.map((i) => ({
      pubkey: i.pubkey,
      programId: i.account.owner,
      accountInfo: SPL_ACCOUNT_LAYOUT.decode(i.account.data)
    }))
  }

  async tokensMetadata(mints: Web3TokensMint[]): Promise<Metadata[]> {
    if (mints.length === 0) return []
    const metadataPDAs = mints.map((mint) => {
      const [metadataPDA] = web3.PublicKey.findProgramAddressSync(
        [
          Buffer.from('metadata'),
          new web3.PublicKey(TOKEN_METADATA_PROGRAM_ID).toBuffer(),
          new web3.PublicKey(mint).toBuffer()
        ],
        new web3.PublicKey(TOKEN_METADATA_PROGRAM_ID)
      )
      return metadataPDA
    })

    const accounts = await this.connection.getMultipleAccountsInfo(metadataPDAs)

    return accounts.filter(Boolean).map((a) => deserializeMetadata(a as unknown as RpcAccount))
  }

  // async tokensMetadata(mints0: Web3TokensMint[]): Promise<FindNftsByMintListOutput> {
  //   const mints = [...new Set(mints0)]
  //   if (mints.length === 0) return []

  //   try {
  //     const metaplex = Metaplex.make(this.connection)
  //     const infos = await metaplex.nfts().findAllByMintList({ mints: mints.map(mint => new web3.PublicKey(mint)) })

  //     console.log({ inputSize: mints.length, outputSize: infos.length }, 'fetch metaplex pair list done')

  //     return infos
  //   } catch (e) {
  //     console.error({ mints, err: e, error: (e as Error).message }, 'Failed to fetch metaplex pair list')
  //     return []
  //   }
  // }

  renderPool(pool: ExtendedLiquidityStateV4): PoolNormalizedType {
    const baseMint = pool.baseMint.toBase58() as Web3TokensMint
    const quoteMint = pool.quoteMint.toBase58() as Web3TokensMint

    if (baseMint === SOLANA_ADDRESS) {
      return {
        pair: pool.pair,
        base_mint: quoteMint,
        quote_mint: baseMint,
        is_reversed: true
      }
    } else if (quoteMint === SOLANA_ADDRESS) {
      return {
        pair: pool.pair,
        base_mint: baseMint,
        quote_mint: quoteMint,
        is_reversed: false
      }
    }

    throw new Error(`Invalid pool ${pool.pair}`)
  }

  async pairStateByMint(mint: Web3TokensMint): Promise<ExtendedLiquidityStateV4 | null> {
    return await getProgramAccounts(this.connection, mint, SOLANA_ADDRESS)
  }

  async pairStateByPair(pair: Web3PairsPair): Promise<ExtendedLiquidityStateV4> {
    return await getMintsByPair(this.connection, pair)
  }

  async poolInfoVerbose(pair: Web3PairsPair): Promise<ExtendedPoolInfoV4> {
    return formatAmmKeysById(this.connection, pair)
  }

  async fetchLiquidityPoolInfo(poolKeys: LiquidityPoolKeys): Promise<LiquidityPoolInfo> {
    let retry = 0
    while (retry <= 3) {
      try {
        const poolInfo = await Liquidity.fetchInfo({ connection: this.connection, poolKeys })
        return poolInfo
      } catch (e) {
        this.logger.error(
          { retry, poolKeys, err: e, error: (e as Error).message },
          `Failed to fetch liquidity pool info`
        )
      }

      await sleep(50 * retry).promise
      retry += 1
    }
    throw new Error(`Block chain congestion`)
  }

  async mintStateVerbose(mint: Web3TokensMint): Promise<ExtendedRawMint> {
    return mintState(this.connection, mint)
  }

  async searchOnchain(query: string): Promise<SearchOnChainResult> {
    if (!isValidSolanaAddress<Web3PairsPair | Web3TokensMint>(query)) {
      return { type: 'invalid', result: null }
    }

    const isMint = web3.PublicKey.isOnCurve(new web3.PublicKey(query))

    try {
      if (isMint) {
        const result = await this.pairStateByMint(query as Web3TokensMint)
        if (result) return { type: 'mint', result: this.renderPool(result) }
        return { type: 'mint', result, mint: query as Web3TokensMint }
      }

      const result = await this.pairStateByPair(query as Web3PairsPair)
      return { type: 'pair', result: this.renderPool(result) }
    } catch (e) {
      this.logger.error({ query, err: e, error: (e as Error).message }, 'search onchain error')
      return { type: 'error', result: null, reason: (e as Error).message }
    }
  }

  async getAccount(mint: string | web3.PublicKey) {
    return await this.connection.getAccountInfo(new web3.PublicKey(mint))
  }

  private fallbackAccountInfo(mint: Web3TokensMint, data: Pick<MintAccountInfoType, 'decimals' | 'program_id'>) {
    const name = mint.substring(0, 6)

    return {
      mint,
      name,
      symbol: name,
      decimals: data.decimals,
      program_id: data.program_id,
      logo: undefined,
      logo_r2_key: undefined,
      tags: [],
      extensions: {}
    }
  }

  async getAccountInfos(mints: Web3TokensMint[]) {
    const infos = await this.connection.getMultipleAccountsInfo(mints.map((mint) => new web3.PublicKey(mint)))

    // https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/raydium/token/token.ts#L122C11-L122C15
    return infos
      .map((info, idx) => {
        if (!info) return null
        const data = MintLayout.decode(info.data)

        return this.fallbackAccountInfo(mints[idx]!, {
          decimals: data.decimals,
          program_id: info.owner.toBase58()
        })
      })
      .filter((t) => !!t)
  }

  async safeRaydiumTokenInfo(mint: Web3TokensMint, cache?: Pick<MintAccountInfoType, 'decimals' | 'program_id'>) {
    try {
      const token = await this.raydium.token.getTokenInfo(mint)

      return {
        mint: token.address as Web3TokensMint,
        name: token.name,
        symbol: token.symbol,
        logo: token.logoURI,
        decimals: token.decimals,
        tags: token.tags,
        extensions: token.extensions,
        logo_r2_key: null,
        program_id: token.programId
      }
    } catch (e) {
      this.logger.error(e, 'safeRaydiumTokenInfo error', mint)

      if (cache) return this.fallbackAccountInfo(mint, cache)

      const info = (await this.getAccountInfos([mint]))[0]

      if (!info) throw e

      return info
    }
  }

  async mintAccountInfo(mint: Web3TokensMint): Promise<MintAccountInfoType> {
    const [token, pumpfun, moonshot] = await this.connection.getMultipleAccountsInfo([
      new web3.PublicKey(mint),
      // https://github.com/rckprtr/pumpdotfun-sdk/blob/main/src/pumpfun.ts#L390
      web3.PublicKey.findProgramAddressSync(
        [Buffer.from('bonding-curve'), new web3.PublicKey(mint).toBytes()],
        new web3.PublicKey(PUMPFUN_PROGRAM)
      )[0],
      // https://github.com/wen-moon-ser/moonshot-sdk/blob/main/src/solana/utils/getCurveAccount.ts#L13
      web3.PublicKey.findProgramAddressSync(
        [Buffer.from('token'), new web3.PublicKey(mint).toBytes()],
        new web3.PublicKey(MOONSHOT_PROGRAM)
      )[0]
    ])

    this.logger.debug(`predicate ${mint}`, { token, pumpfun, moonshot })

    if (!token) throw new Error(`mint ${mint} not found`)

    const data = MintLayout.decode(token.data)

    return {
      decimals: data.decimals,
      program_id: token.owner.toBase58(),
      source: pumpfun ? 'pumpfun' : moonshot ? 'moonshot' : null
    }
  }
}
