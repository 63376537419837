import { ConnectWalletModal, ErrorScreen, NotFoundScreen, Notification } from '@/deprecated-components'
import { usePlatformStore, useWindowResize } from '@/stores'
import { createRootRoute, Outlet, type RouteComponent } from '@tanstack/react-router'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'

const Root: RouteComponent = () => {
  useWindowResize()

  useEffect(() => {
    if (usePlatformStore.getState().current === 'telegram-web') {
      window.Telegram.WebApp.expand()
    }
  }, [])

  // trpc.metadata.useQuery()

  return (
    <>
      <Helmet defaultTitle="TurbX" titleTemplate="TurbX - %s" />
      <Outlet />
      <Notification />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        theme="colored"
      />
      <ConnectWalletModal />
    </>
  )
}

export const Route = createRootRoute({
  notFoundComponent: () => <NotFoundScreen />,
  errorComponent: () => <ErrorScreen />,
  component: Root
})
