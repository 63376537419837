import { css } from '@/styled-system/css'
import { HStack, VStack } from '@/styled-system/jsx'
import { type FC } from 'react'

export interface SliderProps {
  // max?: number
  value: string | null
  onChange: (value: string | null) => void
  unit: string
  color?: string
}

export const Slider: FC<SliderProps> = ({ value, unit, onChange }) => {
  // const percentage = useMemo(() => (max === 0 ? max : ((value / max) * 100).toFixed(0)), [value, max])
  // const steps = [0, 0.25, 0.5, 0.75, 1]
  // const rangeRef = useRef<HTMLDivElement>(null)
  // const [dragConstraint, setDragConstraint] = useState({ left: 0, right: 0 })

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!rangeRef.current) return
  //     const { width } = rangeRef.current.getBoundingClientRect()
  //     setDragConstraint({
  //       left: (-width * Number(percentage)) / 100,
  //       right: (width * (100 - Number(percentage))) / 100
  //     })
  //   })
  // }, [max])

  return (
    <VStack w="100%" gap="24px">
      <HStack w="100%" gap="0">
        {/* <HStack
          paddingInlineStart="24px"
          paddingInlineEnd="14px"
          marginEnd="2px"
          roundedLeft="46px"
          gap="2px"
          h="54px"
          bgColor="rgba(101, 102, 111, 0.51)">
          <input
            onChange={event => onChange((Math.max(0, Number(event.target.value)) / 100) * max)}
            className={css({
              fontSize: '24px',
              fontWeight: '600',
              color: '#EF7A00',
              minW: '2em',
              border: 'none',
              w: '2em',
              textAlign: 'right'
            })}
            value={percentage}
          />
          <Box color="rgba(255, 255, 255, 0.20)" fontWeight="600">
            %
          </Box>
        </HStack> */}
        <input
          className={css({
            paddingInlineStart: '24px',
            border: 'none',
            h: '54px',
            flex: 1,
            minW: 0,
            bgColor: 'rgba(101, 102, 111, 0.51)',
            fontSize: '24px',
            fontWeight: 600,
            color: '#EF7A00'
          })}
          onChange={(event) => onChange(!event.target.value ? null : event.target.value)}
          // min={0}
          // max={max}
          type="number"
          value={value ?? undefined}
        />
        <HStack
          paddingInlineEnd="24px"
          justifyContent="center"
          roundedRight="46px"
          bgColor="rgba(101, 102, 111, 0.51)"
          color="rgba(255, 255, 255, 0.20)"
          fontWeight="600"
          h="54px">
          {unit}
        </HStack>
      </HStack>
      {/* <VStack justifyContent="center" w="100%" h="28px">
        <Box ref={rangeRef} w="100%" bgColor="rgba(255, 255, 255, 0.50)" h="2px" pos="relative">
          {steps.map(step => (
            <Box
              key={step}
              w="2px"
              h="10px"
              pos="absolute"
              top="0"
              transform="translateY(-50%)"
              style={{
                backgroundColor: step * 100 > Number(percentage) ? '#959599' : '#FF7A00',
                left: `${step * 100}%`
              }}
            />
          ))}
          <Box bgColor="#FF7A00" h="2px" style={{ width: `${percentage}%` }} />
          <motion.div
            drag="x"
            dragMomentum={false}
            dragConstraints={dragConstraint}
            dragElastic={0}
            onPan={(event, info) => {
              console.log(event, info, dragConstraint)
            }}
            >
            
            <Circle
              pos="absolute"
              top="50%"
              transform="translate(-50%, -50%)"
              style={{
                left: `${percentage}%`
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <rect width="28" height="28" rx="14" fill="#FF7A00" />
                <path
                  d="M11 8C10.4477 8 10 8.44772 10 9V19C10 19.5523 10.4477 20 11 20C11.5523 20 12 19.5523 12 19V9C12 8.44772 11.5523 8 11 8Z"
                  fill="white"
                  fillOpacity="0.5"
                />
                <path
                  d="M17 8C16.4477 8 16 8.44772 16 9V19C16 19.5523 16.4477 20 17 20C17.5523 20 18 19.5523 18 19V9C18 8.44772 17.5523 8 17 8Z"
                  fill="white"
                  fillOpacity="0.5"
                />
              </svg>
            </Circle>
          </motion.div>
        </Box>
      </VStack> */}
    </VStack>
  )
}
