import { SearchPanel, useSearchModeStore, TradingViewWidget } from '@/deprecated-components'
import { trpc } from '@/stores'
import { css } from '@/styled-system/css'
import { Box, Circle, Container, HStack, VStack } from '@/styled-system/jsx'
import { Link, createFileRoute, type RouteComponent } from '@tanstack/react-router'
import { Spinner, motion } from '@turbx/deprecated-design-system'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { CommentList } from './comment-list.component'
import { getSymbolOfChain } from '@/utils/web3'
import { TopBar } from './topbar.component'
import { Information } from './information.component'
import { TransactionSettings } from './transaction-settings.component'
import { TransactionBuy } from './transaction-buy.component'
import { TransactionSell } from './transaction-sell.component'
import tradingLoadingIcon from './assets/trade-loading.svg'
import { skipToken } from '@tanstack/query-core'
import { useMemo } from 'react'
import { LAMPORTS_PER_SOL } from '@/constant'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TokenPairType = any

const Pair: RouteComponent = () => {
  const { t } = useTranslation()

  const searchMode = useSearchModeStore((state) => state.searchMode)

  const pairAddress = Route.useParams().pairAddress as TokenPairType

  const { data: tokenVerbose, isLoading: isTokenLoading } = trpc.pairInfoSimple.useQuery(
    pairAddress === 'unknown' ? skipToken : { pair: pairAddress },
    { refetchOnMount: 'always' }
  )
  const { data: tokenMetadata } = trpc.pairInfoMetadata.useQuery(
    pairAddress === 'unknown' ? skipToken : { pair: pairAddress },
    { refetchOnMount: 'always' }
  )

  const { data: addressData, isLoading } = trpc.addressQueryCurrent.useQuery(undefined, { refetchOnMount: 'always' })
  const isWalletConnect = addressData?.type === 'completed'
  const { data: addressInfoData } = trpc.addressBalance.useQuery(
    isWalletConnect ? { address: addressData.result.address } : skipToken,
    { refetchOnMount: 'always' }
  )
  const tokenAccountsResponse = trpc.addressTokenAccounts.useQuery(undefined, {
    refetchOnMount: 'always'
  })

  const tokenBalance = useMemo(() => {
    if (tokenAccountsResponse.isLoading) return undefined
    const value =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tokenAccountsResponse.data?.find((i: any) => i.pair?.pairAddresses.includes(pairAddress))?.account?.balance ?? '0'
    return value
  }, [pairAddress, tokenAccountsResponse.data, tokenAccountsResponse.isLoading])

  const balance = useMemo(() => {
    const value = addressInfoData?.balance
    return value ?? 0
  }, [addressInfoData?.balance])

  if (isLoading || isTokenLoading || !tokenVerbose) {
    return (
      <VStack minH="100vh" gap="0" bgColor="#22232B" justifyContent="center">
        <Circle>
          <img src={tradingLoadingIcon} />
        </Circle>
        <Box marginTop="32px" fontSize="14px" fontWeight={400} color="rgba(255, 255, 255, 0.4)">
          {isLoading || isTokenLoading ? (
            <HStack gap="8px">
              <Spinner color="rgba(255, 255, 255, 0.6)" size={12} />
              {t(`routes.pair.loading.message`)}
            </HStack>
          ) : (
            t(`routes.pair.404.message`)
          )}
        </Box>
        {!isTokenLoading && !tokenVerbose && (
          <Link
            to="/"
            className={css({
              marginTop: '80px'
            })}>
            <HStack
              h="48px"
              w="150px"
              rounded="8px"
              bgColor="#575862"
              fontSize="14px"
              fontWeight={590}
              justifyContent="center"
              color="rgba(255, 255, 255, 1)">
              {t(`routes.pair.404.button.label`)}
            </HStack>
          </Link>
        )}
      </VStack>
    )
  }

  const chainSymbol = getSymbolOfChain(tokenVerbose.token)

  return (
    <>
      <Helmet>
        <title>
          {tokenVerbose.token.symbol}/{chainSymbol}
        </title>
      </Helmet>

      <Box pos="absolute" top="-200px">
        <SearchPanel theme="dark" />
      </Box>

      <Container p="0" bg="#22232B" minH="100vh">
        {!searchMode && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.01
              }
            }}>
            <TopBar pair={tokenVerbose} isWalletConnect={isWalletConnect} />
            <TradingViewWidget />
            <VStack p="12px 24px" gap="16px">
              <Information
                token={tokenVerbose.token}
                balance={balance}
                tokenBalance={tokenBalance}
                renounced={tokenMetadata?.renounced}
                burned={(tokenMetadata?.burnedPercentage ?? 0) > 99}
                supply={tokenMetadata?.supply?.uiAmount}
              />
              <>
                <TransactionSettings token={tokenVerbose.token} isWalletConnect={isWalletConnect} />
                {balance !== undefined && (
                  <TransactionBuy
                    balance={balance / LAMPORTS_PER_SOL}
                    token={tokenVerbose.token}
                    pairAddress={tokenVerbose.pair}
                    isWalletConnect={isWalletConnect}
                  />
                )}
                {tokenBalance !== undefined && (
                  <TransactionSell
                    tokenBalance={tokenBalance}
                    pairAddress={tokenVerbose.pair}
                    token={tokenVerbose.token}
                    isWalletConnect={isWalletConnect}
                  />
                )}
                {/* <Wrap
                  rounded="4px"
                  bgColor="#000000"
                  color="rgba(255, 255, 255, 0.7)"
                  fontSize="12px"
                  fontWeight="400"
                  w="100%"
                  gap="8px"
                  p="8px 12px"
                  opacity="0.2"
                  alignItems="center">
                  <Icon
                    name="warning"
                    className={css({
                      w: '16px',
                      h: '16px',
                      color: 'rgba(255, 255, 255, 0.7)'
                    })}
                  />
                  {t(`routes.pair.transaction.tip`)}
                </Wrap> */}
              </>
            </VStack>
            <CommentList pairAddress={tokenVerbose.pair} totalSize={tokenVerbose.comments_count} />
          </motion.div>
        )}
      </Container>
    </>
  )
}

export const Route = createFileRoute('/_authenticated/pairs/$pairAddress')({
  component: Pair
})
