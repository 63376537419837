import { create } from 'zustand'

export const useNavbarStore = create<{
  visible: boolean
  show: () => void
  hide: () => void
}>((set) => ({
  visible: true,
  show: (): void => set({ visible: true }),
  hide: (): void => set({ visible: false })
}))
