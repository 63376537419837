type DataAttribute =
  | 'data-selected'
  | 'data-highlighted'
  | 'data-hover'
  | 'data-active'
  | 'data-checked'
  | 'data-disabled'
  | 'data-readonly'
  | 'data-focus'
  | 'data-focus-visible'
  | 'data-focus-visible-added'
  | 'data-invalid'
  | 'data-pressed'
  | 'data-expanded'
  | 'data-grabbed'
  | 'data-dragged'
  | 'data-orientation'
  | 'data-in-range'
  | 'data-out-of-range'
  | 'data-placeholder-shown'
  | `data-part`
  | `data-attr`
  | `data-placement`
  | `data-theme`
  | `data-size`
  | `data-state`
  | 'data-empty'
  | 'data-loading'
  | 'data-loaded'
  | 'data-enter'
  | 'data-entering'
  | 'data-exited'
  | 'data-exiting'

type Dataset = Partial<Record<DataAttribute, string | boolean | number>>

export const createDataset = (dataset: Dataset): Dataset =>
  (Object.keys(dataset) as DataAttribute[]).reduce<Dataset>((prev, cur) => {
    if (!dataset[cur]) return prev
    return {
      ...prev,
      [cur]: dataset[cur]
    }
  }, {})
