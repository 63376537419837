import type { FC } from 'react'
import { Icon } from '@turbx/deprecated-design-system'
import { TokenAvatar } from './token-avatar.component'
import { intAbbr, numberAbbr } from '@/utils'
import * as styles from './token.css'
import { cx } from '@/styled-system/css'
import { getSymbolOfChain } from '@/utils/web3'
import BigNumber from 'bignumber.js'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedPair = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedTokenWithPrice = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TokenAccountWithBalance = any

export interface TokenPanelProps {
  dark?: boolean
  className?: string
  enableHotEffect?: boolean
  pair?: NormalizedPair
  token: NormalizedTokenWithPrice
  account?: TokenAccountWithBalance['account']
}

const DECIMALS = 2

export const TokenPanel: FC<TokenPanelProps> = ({ className, enableHotEffect, pair, token, account, dark }) => {
  const isDark = !!dark
  const increaseClassNames = [styles.tokenIncrease, isDark && styles.darkTokenIncrease]
  const decreaseClassNames = [styles.tokenIncrease, styles.tokenDecrease, isDark && styles.darkTokenDecrease]

  return (
    <div className={cx(styles.tokenInfo, enableHotEffect && pair?.isPoolHot && styles.hotToken, className)}>
      <TokenAvatar avatar={token.logo} heat={pair?.popularity ?? 0} dark={isDark} />
      <div className={styles.tokenContent}>
        <span className={cx(styles.tokenSymbol, dark && styles.darkMainText)}>
          {token.symbol}
          <span className={cx(styles.pairSymbol, dark && styles.darkText)}> / {getSymbolOfChain(token)}</span>
        </span>
        {pair && (
          <div className={cx(styles.tokenSocialScore, dark && styles.darkText)}>
            <Icon name="chat" className={styles.chatIcon} />
            {intAbbr(pair.comments_count)}
            <div className={styles.tokenSocialScoreSeparator} />
            <Icon name="star-line" className={styles.starIcon} />
            {intAbbr(pair.favorites_count)}
          </div>
        )}
      </div>
      <div className={styles.tokenPriceContainer}>
        {account ? (
          <>
            <span className={cx(dark && styles.holdAmount)}>{numberAbbr(Number(account.balance))}</span>
            <span className={styles.tokenPrice}>
              {token.price?.value ? (
                <>
                  <span className={cx(styles.tokenPriceUnit, dark && styles.darkText)}>$</span>
                  <span className={cx(dark && styles.holdTotal)}>
                    {numberAbbr(Number(BigNumber(account.balance).times(token.price.value).toFixed(DECIMALS)))}
                  </span>
                </>
              ) : (
                <span className={cx(dark && styles.holdTotal)}>-&nbsp;</span>
              )}
            </span>
          </>
        ) : (
          <>
            <span className={styles.tokenPrice}>
              {pair?.token.price?.value ? (
                <>
                  <span className={cx(styles.tokenPriceUnit, dark && styles.darkText)}>$</span>
                  <span className={cx(dark && styles.darkMainText)}>{numberAbbr(pair.token.price.value)}</span>
                </>
              ) : (
                <span className={cx(dark && styles.darkMainText)}>-&nbsp;</span>
              )}
            </span>
            {pair?.token.price?.priceChange24h ? (
              <span
                className={cx(...(pair.token.price?.priceChange24h >= 0 ? increaseClassNames : decreaseClassNames))}>
                {(pair.token.price.priceChange24h / 100).toFixed(2)}%
              </span>
            ) : (
              <span className={cx(...increaseClassNames)}>-&nbsp;</span>
            )}
          </>
        )}
      </div>
    </div>
  )
}
