import { createTRPCReact, type inferReactQueryProcedureOptions } from '@trpc/react-query'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppRouter = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouterInputs = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouterOutputs = any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trpc = createTRPCReact<AppRouter>({}) as any

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>

export type { RouterInputs, RouterOutputs }
