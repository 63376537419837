import { Box } from '@/styled-system/jsx'
import { useEffect, useRef, type FC } from 'react'
import Datafeeds from './trading-view/trading-view-datafeed'

declare const TradingView: {
  widget: new (arg0: {
    container: HTMLElement
    locale: string
    library_path: string
    datafeed: unknown
    symbol: string
    interval: string
    fullscreen: boolean
    debug: boolean
    width: number
    theme: string
    disabled_features: string[]
  }) => unknown
}

export const TradingViewWidget: FC = () => {
  const initialized = useRef(false)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!container.current) return
    if (initialized.current) return

    initialized.current = true

    new TradingView.widget({
      container: container.current,
      locale: 'en',
      library_path: '/charting_library/',
      datafeed: Datafeeds,
      symbol: 'RAY/USDT',
      interval: '1D',
      fullscreen: false,
      debug: true,
      theme: 'dark',
      width: window.innerWidth,
      disabled_features: ['header_widget']
    })
  }, [])

  return <Box w="100%" h="500px" ref={container} />
}
