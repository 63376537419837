import { useEffect, type FC } from 'react'
import { useNavbarStore } from './navbar.hook'
import { AnimatePresence, motion, useAnimate, Icon, type IconProps } from '@turbx/deprecated-design-system'
import * as styles from './navbar.css'
import { Link } from '@tanstack/react-router'

const duration = 0.35

const MotionIcon = motion(Icon)

const NavIcon: FC<{ isActive: boolean } & Pick<IconProps, 'name' | 'className'>> = ({ isActive, ...props }) => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (!isActive) {
      animate(
        scope.current,
        {
          color: '#FFFFFF'
          // filter: `drop-shadow(0px 0px 0px rgba(173, 255, 38, 0))`
        },
        { duration: 0.3 }
      )
    } else {
      animate(
        scope.current,
        {
          color: '#ADFF26'
          // filter: `drop-shadow(0px 0px 7px rgba(173, 255, 38, 0.7))`
        },
        { duration: 0.3 }
      )
    }
  }, [animate, isActive, scope])

  return <MotionIcon ref={scope} {...props} />
}

export const Navbar: FC = () => {
  const visible = useNavbarStore((state) => state.visible)

  return (
    <AnimatePresence>
      {visible && (
        <motion.footer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration }}
          className={styles.footer}>
          <nav>
            <ul className={styles.navbar}>
              <li className={styles.navItem}>
                <Link to="/">
                  {({ isActive }) => <NavIcon isActive={isActive} className={styles.navIcon} name="home-fill" />}
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/token-list">
                  {({ isActive }) => <NavIcon isActive={isActive} className={styles.navIcon} name="coin-fill" />}
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/wallet">
                  {({ isActive }) => <NavIcon isActive={isActive} className={styles.navIcon} name="wallet-line" />}
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/profile">
                  {({ isActive }) => <NavIcon isActive={isActive} className={styles.navIcon} name="user-fill" />}
                </Link>
              </li>
            </ul>
          </nav>
        </motion.footer>
      )}
    </AnimatePresence>
  )
}
