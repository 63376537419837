import { Modal, connectWalletModalId, useModal, useNotificationStore } from '@/deprecated-components'
import { trpc } from '@/stores'
import { css } from '@/styled-system/css'
import { HStack, Wrap, Divider, Grid, GridItem, Flex, Box, VStack } from '@/styled-system/jsx'
import { getSymbolOfChain } from '@/utils/web3'
import { useState, type FC } from 'react'
import { useTransactionSettingsStore } from './transaction-settings.store'
import { useTranslation } from 'react-i18next'
import { createDataset } from '@/utils'
import { APP_ENVIRONMENT, LAMPORTS_PER_SOL } from '@/constant'
import { Spinner } from '@turbx/deprecated-design-system'
import { captureException } from '@sentry/react'
import { toast } from 'react-toastify'
import fromExponential from 'from-exponential'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalizedToken = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SwapInputWithPair = any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TokenPairType = any

const RANGE_BUY_AMOUNT = [0.2, 0.5, 1, 3, 5]
const MIN_BUY_AMOUNT = APP_ENVIRONMENT !== 'production' ? 0.000001 : 0.01

export interface TransactionBuyProps {
  balance: number
  token: NormalizedToken
  pairAddress: TokenPairType | undefined
  isWalletConnect: boolean
}

export const TransactionBuy: FC<TransactionBuyProps> = ({ token, pairAddress, isWalletConnect, balance }) => {
  const { t } = useTranslation()
  const utils = trpc.useUtils()
  const alert = useNotificationStore((state) => state.alert)

  const chainSymbol = getSymbolOfChain(token)

  const buyConfirmModal = useModal()
  const buyCustomModal = useModal()
  const connectWalletModal = useModal(connectWalletModalId)

  const slippage = useTransactionSettingsStore((state) => state.slippage)
  const priorityFee = useTransactionSettingsStore((state) => state.priorityFee)
  const [buyAmount, setBuyAmount] = useState<number | null>(0)

  const isInsufficientBalance = balance < (buyAmount ?? 0)
  // const isInsufficientBalance = false
  const validBuyAmount = pairAddress && (buyAmount ?? 0) >= MIN_BUY_AMOUNT && !isInsufficientBalance

  const [buying, setBuying] = useState(false)

  const updateBuyAmount = (value: number | null): void => setBuyAmount(value ? value : null)

  const exchangeConfirm = trpc.exchangeConfirmTransaction.useMutation()
  const exchangeBuy = trpc.exchangeSwap.useMutation()
  // const exchangePreflight = trpc.exchangePreflight.useMutation()

  const confirmBuy = async (): Promise<void> => {
    if (buying) return

    // 1. 前端校验
    if (!pairAddress) {
      alert(t(`routes.pair.transaction.error.default`))
      return
    }

    if (isInsufficientBalance) {
      alert(t(`routes.pair.transaction.error.insufficientBalance`))
      return
    }

    setBuying(true)

    const params: SwapInputWithPair = {
      tradeType: 'out',
      pair: pairAddress,
      slippage: slippage === 'Auto' ? { type: 'auto' } : { type: 'constant', value: Math.round(slippage * 10) },
      priorityFeeLamports: Math.round(priorityFee * LAMPORTS_PER_SOL),
      amount: fromExponential(buyAmount ?? 0)
    }

    const onTerminal = (): void => {
      buyCustomModal.close()
      setBuyAmount(0)
      setBuying(false)
    }

    // 2. preflight
    // try {
    //   const data = await exchangePreflight.mutateAsync(params)
    //   if (Number(data.minAmountOut) <= 0) {
    //     throw new Error(t(`routes.pair.transaction.error.insufficientBalance`))
    //   }
    // } catch (error) {
    //   onTerminal()
    //   // @ts-expect-error 错误类型不可知
    //   alert(error?.message ?? t(`routes.pair.transaction.error.default`))
    //   console.error(error)
    //   captureException(error)
    //   return
    // }

    try {
      // 3.swap send
      const response = await exchangeBuy.mutateAsync(params)
      toast.info(t(`routes.pair.transaction.sent.message`))
      onTerminal()

      // 4. confirm
      await exchangeConfirm.mutateAsync({
        maskedExchangeRecordId: response.recordId,
        transactionId: response.transactionId
      })

      utils.addressBalance.invalidate()
      utils.addressTokenAccounts.invalidate()

      // 由于 confirm 的时机比较早，余额不一定正确更新了，多触发两次
      setTimeout(() => {
        utils.addressBalance.invalidate()
        utils.addressTokenAccounts.invalidate()
      }, 3000)

      setTimeout(() => {
        utils.addressBalance.invalidate()
        utils.addressTokenAccounts.invalidate()
      }, 6000)

      onTerminal()

      toast.success(t(`routes.pair.transaction.success.message`))
    } catch (error) {
      onTerminal()
      toast.error(t(`routes.pair.transaction.failed.message`))
      console.error(error)
      captureException(error)
    }
  }

  return (
    <>
      <HStack w="100%" justifyContent="space-between" gap="20px">
        <Wrap flexWrap="nowrap" gap="8px" alignItems="center" alignSelf="flex-start" color="#ffffff" fontSize="16px">
          <Divider thickness="2px" color="#ADFF26" h="15px" orientation="vertical" />
          {t(`routes.pair.transaction.buy.label`)}
        </Wrap>
        <Grid columns={3} gap="8px" flex="1">
          {RANGE_BUY_AMOUNT.map((item) => (
            <GridItem
              key={item}
              px="9.5px"
              h="37px"
              bgColor="#34382E"
              border="1px solid rgba(173, 255, 38, 0.22)"
              rounded="4px"
              onClick={() => {
                if (!isWalletConnect) {
                  connectWalletModal.open()
                  return
                }

                updateBuyAmount(item)
                buyConfirmModal.open()
              }}>
              <Flex
                h="100%"
                w="100%"
                alignItems="center"
                justifyContent="center"
                color="#ADFF26"
                fontSize="14px"
                fontWeight="510">
                {item} {chainSymbol}
              </Flex>
            </GridItem>
          ))}
          <GridItem px="9.5px" h="37px" bgColor="#22232B" border="1px solid rgba(173, 255, 38, 0.22)" rounded="4px">
            <Flex
              onClick={() => {
                if (!isWalletConnect) {
                  connectWalletModal.open()
                  return
                }
                buyCustomModal.open()
              }}
              h="100%"
              w="100%"
              alignItems="center"
              justifyContent="center"
              color="#ADFF26"
              fontSize="14px"
              fontWeight="510">
              {t(`routes.pair.transaction.buy.custom.label`)}
            </Flex>
          </GridItem>
        </Grid>
      </HStack>

      <Modal modalId={buyCustomModal.modalId} closeOnClickOverlay={!buying}>
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          gap="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)">
          <Wrap
            gap="8px"
            w="100%"
            alignItems="center"
            textAlign="left"
            fontSize="16px"
            fontWeight="510"
            color="#FFFFFF"
            textTransform="capitalize">
            <Divider orientation="vertical" color="#ADFF26" thickness="2px" h="15px" />
            {t(`routes.pair.transaction.buy.label`)} {token.name}
          </Wrap>
          <input
            type="number"
            className={css({
              w: '100%',
              h: '54px',
              px: '24px',
              rounded: '46px',
              border: '1px solid rgba(173, 255, 38, 0.5)',
              bgColor: `rgba(101, 102, 111, 0.51)`,
              caretColor: 'rgba(173, 255, 38, 1)',
              color: 'rgba(173, 255, 38, 1)',
              fontSize: '24px',
              fontWeight: 600
            })}
            value={buyAmount ?? undefined}
            onChange={(e) => updateBuyAmount(e.target.value ? Number(e.target.value) : null)}
            min={MIN_BUY_AMOUNT}
            max={balance}
          />
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="4"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#575862"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={() => {
                if (!buying) buyCustomModal.close()
              }}>
              {t(`routes.pair.modal.buyCustom.cancel.label`)}
            </HStack>
            <HStack
              justifyContent="center"
              flex="11"
              h="48px"
              rounded="8px"
              color="#000000"
              background="#ADFF26"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              {...createDataset({
                'data-disabled': !validBuyAmount
              })}
              _disabled={{
                color: '#FFFFFF',
                background: '#575862'
              }}
              onClick={async () => {
                await confirmBuy()
              }}>
              {buying && (
                <>
                  <Spinner color="rgba(0, 0, 0, 1)" size={24} />
                </>
              )}
              {!buying && !isInsufficientBalance && t(`routes.pair.modal.buyCustom.confirm.label`)}
              {!buying && isInsufficientBalance && t(`routes.pair.transaction.error.insufficientBalance`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>

      <Modal modalId={buyConfirmModal.modalId} closeOnClickOverlay={!buying}>
        <VStack
          w="calc(100% - 35px * 2)"
          mx="auto"
          p="24px"
          gap="24px"
          rounded="16px"
          bgColor="rgba(46, 47, 56, 0.90)"
          backdropFilter="blur(6px)">
          <Wrap
            gap="8px"
            w="100%"
            alignItems="center"
            textAlign="left"
            fontSize="16px"
            fontWeight="510"
            color="#FFFFFF"
            textTransform="capitalize">
            <Divider orientation="vertical" color="#ADFF26" thickness="2px" h="15px" />
            {t(`routes.pair.transaction.buy.label`)} {token.name}
          </Wrap>
          <Wrap alignItems="flex-end" gap="4px" color="#ADFF26" fontSize="32px" fontWeight="600" wordBreak="break-all">
            {buyAmount}
            <Box color="rgba(255, 255, 255, 0.20)" fontSize="24px">
              {chainSymbol}
            </Box>
          </Wrap>
          <HStack w="100%" gap="12px">
            <HStack
              justifyContent="center"
              flex="4"
              h="48px"
              rounded="8px"
              color="#FFFFFF"
              background="#575862"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              onClick={() => {
                if (!buying) buyConfirmModal.close()
              }}>
              {t(`routes.pair.modal.buyConfirm.cancel.label`)}
            </HStack>
            <HStack
              justifyContent="center"
              flex="11"
              h="48px"
              rounded="8px"
              color="#000000"
              background="#ADFF26"
              fontSize="14px"
              fontWeight="590"
              textTransform="capitalize"
              {...createDataset({
                'data-disabled': !validBuyAmount
              })}
              _disabled={{
                color: '#FFFFFF',
                background: '#575862'
              }}
              onClick={async () => {
                await confirmBuy()
              }}>
              {buying && (
                <>
                  <Spinner color="rgba(0, 0, 0, 1)" size={24} />
                </>
              )}
              {!buying && !isInsufficientBalance && t(`routes.pair.modal.buyConfirm.confirm.label`)}
              {!buying && isInsufficientBalance && t(`routes.pair.transaction.error.insufficientBalance`)}
            </HStack>
          </HStack>
        </VStack>
      </Modal>
    </>
  )
}
